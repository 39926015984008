import styled from "styled-components";

export const LanguageStyled = styled.section`
  display: flex;
  align-items: center;
  svg {
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.primaryOrange};
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 0.77em;
    }
    cursor: pointer;
  }
  span {
    padding-right: 0.8em;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 0.9em;
    }
  }
`;
