import React from "react";
import { LoadNewStyled, Loader, Slider } from "./style";
import { defaultSwitch } from "../../function";
const LoadingNew = ({ display }) => {
  return (
    <LoadNewStyled
    animation={defaultSwitch(
      "change",
      !display.load,
      "slid 1.2s linear forwards",
      "unset"
    )}
    display={defaultSwitch("change", display.loaderDisplay, "flex", "none")}
  >
    <Slider
      animation={defaultSwitch(
        "change",
        !display.load,
        "disappear .4s linear forwards",
        "unset"
      )}
      display={defaultSwitch("change", display.loaderDisplay, "flex", "none")}
    >
      <Loader />
      <span>Arab Metals</span>
    </Slider>
  </LoadNewStyled>
  );
};

export default LoadingNew;
