import React, { useState, useEffect } from "react";
import { Control, Ligne, ProcessTxt, ProceStyled } from "./style";
import { Container } from "../../styles/container";
import { AboutSection, Border, Img, Picture } from "../Main/about/style";
import Flex from "../../styles/flex";
import { theme } from "../../styles/theme";
import { Display, GlobalSwitch } from "../../function";
import Quality from "./Quality";
import Title from "../common/title";

const Process = ({ display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 3050 : 1750;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  return (
    <ProceStyled>
      <Ligne>
        <Container>
          <Title
            color={theme.colors.primaryOrange}
            txt={data.lignes}
            align={display.lang === "AR" ? "end" : "start"}
            position={display.lang === "AR" ? "right:0;" : "left:0;"}
          />
          <Flex
            width={"100%"}
            direction={"column"}
            justify={"center"}
            padding={"4em 0em"}
          >
            <ProcessTxt
              animation={GlobalSwitch(
                "change",
                localScroll,
                Breakpoint,
                "animation: ApparitionLeft .5s forwards",
                null
              )}
            >
              <p style={{ textAlign: display.lang === "AR" ? "end" : "start" }}>
                {data.process.txtSecond}
              </p>
            </ProcessTxt>
          </Flex>
        </Container>
      </Ligne>
      <Control>
        <Container>
          <Title
            color={theme.colors.primaryWhite}
            txt={data.process.title}
            align={display.lang === "AR" ? "end" : "start"}
            position={display.lang === "AR" ? "right:0;" : "left:0;"}
          />
          <Flex
            padding={GlobalSwitch(
              "changeRev",
              display.screen,
              1024,
              "1em 0em 2em 0em",
              "1em 0em 5em 0em"
            )}
            direction={GlobalSwitch(
              "changeRev",
              display.screen,
              1024,
              "column",
              null
            )}
          >
            <Picture
              justify={"center"}
              animation={GlobalSwitch(
                "change",
                localScroll,
                Breakpoint,
                "animation: ApparitionLeft .5s forwards",
                null
              )}
            >
              <Border>
                <Img
                  src={`${process.env.PUBLIC_URL}${data.process.img}`}
                  alt="about-img"
                />
              </Border>
            </Picture>
            <AboutSection
              color={theme.colors.primaryWhite}
              animation={GlobalSwitch(
                "change",
                localScroll,
                Breakpoint,
                "animation: ApparitionLeft .5s forwards",
                null
              )}
            >
              <section
                style={{ textAlign: display.lang === "AR" ? "end" : "start",fontSize:"1.5em" }}
              >
                <ul>
                <li>{data.process.txt2}</li>
                <li>{data.process.txt3}</li>
                <li>{data.process.txt4}</li>
                </ul>
                
                
              </section>
            </AboutSection>
          </Flex>
        </Container>
      </Control>
      <Quality data={data} display={display} />
    </ProceStyled>
  );
};

export default Process;
