import React, { useState, useEffect } from "react";
import { Display, GlobalSwitch } from "../../function";
import { Container } from "../../styles/container";
import Flex from "../../styles/flex";
import {
  ContactInfo,
  Iframe,
  MapContent,
  MapStyled,
  QrCode,
  Infomations,
} from "./style";
import qrcode from "../../assets/qrcodeArabMetals.svg";
const Map = ({ display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 70 : 1620;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  return (
    <MapStyled>
      <Container>
        <Flex
          width={"100%"}
          direction={GlobalSwitch(
            "changeRev",
            display.screen,
            1024,
            "column-reverse",
            null
          )}
          position={"relative"}
        >
          <ContactInfo
            animation={GlobalSwitch(
              "change",
              localScroll,
              Breakpoint,
              "animation: ApparitionLeft .5s forwards",
              null
            )}
          >
            <Flex
              direction={GlobalSwitch(
                "changeRev",
                display.screen,
                1024,
                "row",
                "column"
              )}
              width={"100%"}
            >
              <QrCode
                display={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint + 1300,
                  "flex",
                  null
                )}
              >
                <img src={qrcode} alt="qrcode" />
              </QrCode>
              <Infomations>
                <Flex
                  direction={GlobalSwitch(
                    "changeRev",
                    display.screen,
                    1024,
                    "row",
                    "column"
                  )}
                  width={"100%"}
                  align={display.lang === "AR" ? "flex-end" : "flex-start"}
                  wrap={"wrap"}
                >
                  <Flex
                    direction={"column"}
                    align={GlobalSwitch(
                      "changeRev",
                      display.screen,
                      1024,
                      "flex-start",
                      null
                    )}
                  >
                    <span
                      style={{
                        textAlign: display.lang === "AR" ? "end" : "start",
                      }}
                    >
                      {data.FormulaireDeContact.ad}{" "}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection:
                          display.lang === "AR" ? "row-reverse" : "row",
                      }}
                    >
                      <p
                        style={{
                          margin:
                            display.lang === "AR"
                              ? "0 0 0 .3em"
                              : "0 0.3em 0 0",
                        }}
                      >
                        {data.FormulaireDeContact.rue}
                      </p>
                      <p>{data.FormulaireDeContact.adress}</p>
                    </div>
                  </Flex>
                  <Flex
                    direction={"column"}
                    align={GlobalSwitch(
                      "changeRev",
                      display.screen,
                      1024,
                      "flex-start",
                      null
                    )}
                  >
                    {" "}
                    <span
                      style={{
                        textAlign: display.lang === "AR" ? "end" : "start",
                      }}
                    >
                      {data.FormulaireDeContact.phone}{" "}
                    </span>
                    <p>+213 770 65 94 87</p>
                  </Flex>
                  <Flex
                    direction={"column"}
                    align={GlobalSwitch(
                      "changeRev",
                      display.screen,
                      1024,
                      "flex-start",
                      null
                    )}
                  >
                    <span
                      style={{
                        textAlign: display.lang === "AR" ? "end" : "start",
                      }}
                    >
                      {data.FormulaireDeContact.horaires}
                    </span>
                    <p>{data.time} 08:00 / 17:00</p>
                  </Flex>
                </Flex>
              </Infomations>
            </Flex>
          </ContactInfo>
          <MapContent
            animation={GlobalSwitch(
              "change",
              localScroll,
              Breakpoint,
              "animation: ApparitionRight .5s forwards",
              null
            )}
          >
            <Flex width={"100%"} justify={"center"}>
              <Iframe
                title="Adresse position"
                src={
                  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38311.58784013849!2d3.0374198428504866!3d36.76480433398134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fb1f69fcf0a11%3A0xdadc75ba3495e20a!2sELSEWEDY%20ELECTRIC%20ALGERIA%20ENERGY%20SOLUTIONS!5e0!3m2!1sfr!2sdz!4v1680143075483!5m2!1sfr!2sdz"
                }
                loading="lazy"
                allowfullscreen=""
                referrerpolicy="no-referrer-when-downgrade"
                height={GlobalSwitch(
                  "changeRev",
                  display.screen,
                  1024,
                  "24em",
                  "36em"
                )}
              ></Iframe>
            </Flex>
          </MapContent>
        </Flex>
      </Container>
    </MapStyled>
  );
};

export default Map;
