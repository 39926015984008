import React, { useState, useEffect } from "react";
import { ContactStyled } from "./style";
import { Container } from "../../styles/container";
import Flex from "../../styles/flex";
import Title from "../common/title";
import { Display, GlobalSwitch, setFunction } from "../../function";
import { Form, Input, Send, TextArea } from "../Main/Devis/style";
import { theme } from "../../styles/theme";
import Phone from "./SelectPhone";
import { Helmet } from "react-helmet-async";
const Contact = ({ setDisplay, display, flag, parameter, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 40 : 650;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    setFunction(setDisplay, "devisBtn", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  const email = `https://formsubmit.co/${parameter.email}`;
  return (
    <>
      <Helmet>
        <title>CONTACT ARAB METLAS</title>
        <meta
          name="description"
          content={data.FormulaireDeContact.repAuto.email}
        />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <ContactStyled>
        <Container>
          <Flex
            direction={"column"}
            align={"flex-start"}
            padding={"10em 0em 5em 0em"}
          >
            <Title txt={data.FormulaireDeContact.title}align={display.lang === "AR" ? "end" : "start"}
              position={display.lang === "AR" ? "right:0;" : "left:0;"} />
            <Flex
              padding={"2em 2em"}
              justify={"space-evenly"}
              width={"100%"}
              wrap={"wrap"}
            >
              <Form action={email} method="POST" direction={"column"}>
                <Input type="hidden" name="_template" value="basic"></Input>
                <Input
                  type="hidden"
                  name="_autoresponse"
                  value={data.FormulaireDeContact.repAuto}
                ></Input>
                <Input type="text" name="_honey" style={{ display: "none" }} />

                <Flex
                  width={"100%"}
                  justify="space-around"
                  direction={GlobalSwitch(
                    "changeRev",
                    display.screen,
                    1024,
                    "column",
                    null
                  )}
                >
                  <Flex direction={"column"} width={"40%"}>
                    <Input
                      type="text"
                      name="name"
                      placeholder={data.FormulaireDeContact.nom}
                      required
                      animation={GlobalSwitch(
                        "change",
                        localScroll,
                        Breakpoint,
                        "animation:ApparitionBottom .5s forwards",
                        null
                      )}
                      bg={theme.colors.secondaryGray}
                      border={theme.colors.primaryGray}
                      color={theme.colors.primaryGray}
                      width={"75%"}
                      padding={".8em 1em"}
                    />
                    <Input
                      type="email"
                      name="email"
                      placeholder={data.FormulaireDeContact.email}
                      required
                      animation={GlobalSwitch(
                        "change",
                        localScroll,
                        Breakpoint,
                        "animation:ApparitionBottom .5s forwards",
                        null
                      )}
                      bg={theme.colors.secondaryGray}
                      border={theme.colors.primaryGray}
                      color={theme.colors.primaryGray}
                      width={"75%"}
                      padding={".8em 1em"}
                    />
                  </Flex>
                  <Flex
                    direction={"column"}
                    width={"40%"}
                    position={"relative"}
                  >
                    <Phone
                      data={data}
                      setDisplay={setDisplay}
                      display={display}
                      bg={theme.colors.secondaryGray}
                      color={theme.colors.primaryGray}
                      width={"92%"}
                      parentWidth={"82%"}
                      height={"9em"}
                      widthList={"100%"}
                      widthIcon={GlobalSwitch(
                        "change",
                        display.screen,
                        1024,
                        "18%",
                        "26%"
                      )}
                      animation={GlobalSwitch(
                        "change",
                        localScroll,
                        Breakpoint,
                        "animation:ApparitionBottom .5s forwards",
                        null
                      )}
                      flag={flag}
                    />
                    <Input
                      type="text"
                      name="Sujet"
                      placeholder={data.FormulaireDeContact.sujet}
                      required
                      animation={GlobalSwitch(
                        "change",
                        localScroll,
                        Breakpoint,
                        "animation:ApparitionBottom .5s forwards",
                        null
                      )}
                      bg={theme.colors.secondaryGray}
                      border={theme.colors.primaryGray}
                      color={theme.colors.primaryGray}
                      width={"75%"}
                      padding={".8em 1em"}
                    />
                  </Flex>
                </Flex>
                <Flex width={"100%"} justify={"center"}>
                  <TextArea
                    type="text"
                    name="message"
                    placeholder={data.FormulaireDeContact.msg}
                    animation={GlobalSwitch(
                      "change",
                      localScroll,
                      Breakpoint,
                      "animation:ApparitionBottom .5s forwards",
                      null
                    )}
                    bg={theme.colors.secondaryGray}
                    border={theme.colors.primaryGray}
                    color={theme.colors.primaryGray}
                  />
                </Flex>

                <Send
                  animation={GlobalSwitch(
                    "change",
                    localScroll,
                    Breakpoint,
                    "animation:ApparitionBottom .5s forwards",
                    null
                  )}
                  bg={theme.colors.secondaryGray}
                  border={`1px solid${theme.colors.primaryGray}`}
                  color={theme.colors.primaryGray}
                  width={"20%"}
                >
                  {data.FormulaireDeContact.envoyer}
                </Send>
              </Form>
            </Flex>
          </Flex>
        </Container>
      </ContactStyled>
    </>
  );
};

export default Contact;
