import styled from "styled-components";

export const FooterStyled = styled.footer`
  background-color: ${({ theme }) => theme.colors.primaryFooter};
  color: ${({ theme }) => theme.colors.primaryWhite};
  overflow-x: hidden;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 10px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

  position: relative;
  svg {
    position: absolute;
    z-index: 1;
    top: 0.1em;
    right: 0.1em;
    color: ${({ theme }) => theme.colors.primaryGray};
    font-size: 1.5em;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryOrange};
    }
  }
`;
export const Newsletters = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
  p {
    padding: 0em 4em;
    font-size: 1.2em;
    margin-bottom: 2em;
    text-align: justify;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      padding: 5em 5em 1em 5em;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
      padding: 5em 2em 1em 2em;
    }
  }
`;
export const Center = styled.section`
  flex: 1;
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  align-items: ${({ align }) => align || "center"};
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
`;
export const LogoFooter = styled.img`
  width: 25%;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 20%;
    padding: 0em 0em 6em 0em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    width: 30%;
  }
`;
export const SocialFooter = styled.section`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 4em 0em 0em 0em;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    display: none;
  }
  a {
    color: inherit;
  }
  svg {
    font-size: 2em;
    position: relative;
    top: 0;
    flex: 1;
    color: white;
  }
`;
export const Info = styled.section`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
    padding: 0em;
    flex-direction: column;
  }
`;
export const InfoContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }) => padding || "0em 1em 0em 2em"};
  border-left: ${({ border }) => border || "none"};
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
    padding: 0em;
    flex-direction: column;
    border-left: none;
  }
  span {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 1.5em;
  }
  p {
    font-size: 1em;
    width: 100%;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
  svg {
    font-size: 1.3em;
    position: relative;
    top: 0;
    padding: 0em 1em 0em 0em;
    margin-bottom: 0.5em;
    color: white;
  }
  ul {
    li {
      margin-bottom: 0.5em;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a {
        color: ${({ theme }) => theme.colors.primaryWhite};
        text-decoration: none;
      }
    }
  }
  font-size: 1em;
`;
export const MenuList = styled.ul`
  font-size: 1em;
  li {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      color: ${({ theme }) => theme.colors.primaryWhite};
      text-decoration: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    ${({ mobile }) => mobile || null}
  }
`;
export const Copyright = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryGray};
  color: ${({ theme }) => theme.colors.primaryGray};
  span {
    padding: 0.7em 0em;
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondaryGray};
    a {
      color: #1971c2;
      text-decoration: none;
      font-style: italic;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    font-size: 0.8em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    font-size: 0.6em;
  }
`;
export const ContactList = styled.li`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  svg {
    padding: ${({ padding }) => padding || "none"};
  }
  p {
    text-align: ${({ align }) => align || "center"};
  }
`;
