import React, { useState, useEffect } from "react";
import { StatStyled, Text } from "./style";
import Flex from "../../../styles/flex";
import { GiChart } from "react-icons/gi";
import { IoHourglassOutline } from "react-icons/io5";
import { BiMedal } from "react-icons/bi";
import { Display, GlobalSwitch } from "../../../function";
import CountUp from "react-countup";
const Stats = ({ display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 60 : 200;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  return (
    <StatStyled
      animation={GlobalSwitch(
        "change",
        localScroll,
        Breakpoint,
        "animation: Apparition .5s forwards",
        null
      )}
    >
      <Flex width={"100%"} justify={"space-evenly"}>
        <Flex direction={"column"}>
          <BiMedal />
          <p>
            <CountUp delay={10} end={data.stats[0]} suffix={"%"} duration={4} />
          </p>
          <Text>{data.stats[1]}</Text>
        </Flex>

        <Flex direction={"column"}>
          <GiChart />
          <p>{data.stats[2]}</p>

          <Text>{data.stats[3]}</Text>
        </Flex>

        <Flex direction={"column"}>
          <IoHourglassOutline />
          <p>
            {`${new Date().getFullYear() - 2012}
            
            ${data.stats[5]}`}
          </p>
          <Text>{data.stats[4]}</Text>
        </Flex>
      </Flex>
    </StatStyled>
  );
};

export default Stats;
