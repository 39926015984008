import React from "react";
import { MdOutlineLanguage } from "react-icons/md";
import { setFunction } from "../../function";
import { LanguageStyled } from "./style";
import { theme } from "../../styles/theme";
const Language = ({ setDisplay, display }) => {
  return (
    <LanguageStyled>
      <span>{display.error === 404 ? null : display.lang}</span>
      <MdOutlineLanguage
        onClick={() =>
          setFunction(
            setDisplay,
            "languagePanel",
            display.languagePanel ? false : true
          )
        }
        style={{
          color: display.error === 404 ? "red" : theme.colors.primaryOrange,
        }}
      />
    </LanguageStyled>
  );
};

export default Language;
