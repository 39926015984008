import styled from "styled-components";
export const TitleStyled = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  padding-bottom: 2em;
  width: 100%;
  position: relative;
  color: ${({ color }) => color || "inherit"};
  text-align: ${({ align }) => align || "none"};
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    font-size: 1.7em;
  }
  &:before {
    content: "";
    width: 2em;
    height: 0.2em;
    position: absolute;
    top: 40%;
    ${({ position }) => position || "none"}
    background-color: ${({ color, theme }) =>
      color || theme.colors.primaryGray};
    transform-origin: left top;
    transform: scale(1, 1);
  }
`;
