import styled from "styled-components";

export const BottomStyled = styled.nav`
  width: 100%;
  position: sticky;
  top: ${({ position }) => position};
  margin-top: -5em;
  z-index: 3;
  background-color: ${({ bg }) => bg || "transparent"};
  border-bottom: ${({ border }) => border || "none"};
  user-select: none;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    margin-top: -7em;
  }
`;
export const Menu = styled.section`
  width: 85%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.primaryGray};
  border-radius: 0.5em;
`;
export const Logo = styled.img`
  padding: 0em 1em;
  flex: 1;
  transform: ${({ scale }) => scale};
  transition: 0.8s ease-in;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    transform: ${({ scale }) => scale};
    padding: 0em 0em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    padding-left: 2em;
  }
`;
export const MenuList = styled.ul`
  display: ${({ display }) => display || "none"};
  flex-direction: ${({ direction }) => direction || "row"};
  text-align: ${({ align }) => align || "unset"};
  list-style: none;
  justify-content: space-evenly;
  flex: 4;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
`;

export const List = styled.li`
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-size: ${({ size }) => size || "1.2em"};
  position: relative;
  padding: ${({ padding }) => padding || "unset"};
  transition: transform 0.2s ease-in;
  ${({ align }) => align};
  cursor: pointer;
  a {
    color: ${({ theme }) => theme.colors.primaryWhite};
    text-decoration: none;
  }
  &:before {
    content: "";
    width: 3em;
    height: 0.2em;
    position: absolute;
    top: 1.2em;
    ${({ position }) => position || "none"}
    background-color: ${({ theme }) => theme.colors.primaryOrange};
    transform-origin: left top;
    transform: scale(0, 1);
    transition: color 0.1s, transform 0.2s ease-out;
  }
  &:hover::before {
    transform-origin: ${({origin})=>origin} top;
    transform: scale(1, 1);
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      transform-origin: left top;
      transform: scale(0, 1);
    }
  }
`;

export const DevisBtn = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.primaryOrange};
  flex: 1;
  padding: ${({ padding }) => padding || "2.3em 0em"};
  font-size: 1.2em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primaryWhite};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  a {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primaryOrange};
    background-color: ${({ theme }) => theme.colors.primaryWhite};
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    display: none;
  }
`;
export const TopSocial = styled.ul`
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  flex: 2;
  transition: 0.2s ease-in-out;
  li {
    color: ${({ theme }) => theme.colors.primaryWhite};
    font-size: 1.2em;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryOrange};
    }
    a {
      color: inherit;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    display: none;
  }
`;
export const MenuMobile = styled.section`
  font-size: 2.5em;
  flex: 1;
  padding: 1em 0.5em;
  display: none;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.primaryWhite};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    display: flex;
    padding: 0em 0.5em;
  }
`;
export const MenuMobileContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 0em;
  background-color: ${({ theme }) => theme.colors.primaryGray};
  color: ${({ theme }) => theme.colors.primaryWhite};
  width: ${({ width }) => width};
  right: 0;
  transition: width 0.3s ease-in;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  svg {
    cursor: pointer;
    font-size: 2em;
    position: relative;
    right: 0;
    padding: 1em 1em 0em 0em;
    transition: width 0.3s ease-in;
  }
`;
export const MobileLang = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 2em;
  svg {
    cursor: pointer;
    font-size: 2em;
    position: relative;
    right: 0;
    padding: 0em;
    transition: width 0.3s ease-in;
  }
`;
