import styled from "styled-components";

export const DeviByProductStyled = styled.section``;
export const DevisPanel = styled.section`
  width: 50%;
  position: absolute;
  top: 0%;
  left: ${({ left }) => left || "0%"};
  opacity: ${({ opacity }) => opacity || "1"};
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1;
  padding: 0em 2em 1.7em 2em;
  transition: 0.3s ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    display: ${({ display }) => display};
    width: 100%;
    z-index: 2;
    left: 0%;
  }
`;
export const DevisInput = styled.section`
  display: flex;
  flex-direction: column;
  width: 45%;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
`;
