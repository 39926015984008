import styled from "styled-components";

export const ProductStyled = styled.main`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  padding-top: 2em;
  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
`;

export const Card = styled.section`
  width: ${({ width }) => width || "100%"};
  color: ${({ theme }) => theme.colors.secondaryGray};
  background-color: ${({ theme }) => theme.colors.secondaryGray};
  border-bottom: 3px solid ${({ theme }) => theme.colors.primaryGray};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  opacity: ${({ opacity }) => opacity || "none"};
  margin-bottom: 2em;
  border-radius: 1em;
  ${({ animation }) => animation || "none"};

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  &:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.primaryOrange};
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 85%;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 1em;
  transition: all 0.3s ease-in;
  &:hover {
    transform: scale(1.06);
    border-radius: 2em;
  }
  &::after {
    width: 100%;
    background-color: red;
    top: 0;
  }
`;
export const Txt = styled.p`
  font-size: 1.2em;
  font-weight: 600;
  padding: 2em 2em;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryGray};
`;
export const Btn = styled.button`
  width: 10%;
  font-size: ${({ size }) => size || "1em"};
  height: max-content;
  text-align: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s ease-in;
  position: relative;
  opacity: ${({ opacity }) => opacity || "none"};
  ${({ animation }) => animation || "none"};
  svg {
    color: ${({ theme }) => theme.colors.primaryOrange};
  }

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primaryOrange};
  }
  a {
    position: absolute;
    top: 10%;
    left: 40%;
    opacity: 1;
    font-size: 1em;
    text-decoration: none;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 25%;
  }
`;
