import React from "react";
import Flex from "../../styles/flex";
import { Typewriter } from "react-simple-typewriter";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Carousel } from "react-responsive-carousel";
import { HeaderStyled, Field, TypeContainer } from "./style";
import { Container } from "../../styles/container";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { GlobalSwitch } from "../../function";
import { Form, Send } from "../Main/Devis/style";
const Header = ({ display, data, parameter }) => {
  const email = `https://formsubmit.co/${parameter.emailNewsletters}`;
  return (
    <>
      <HeaderStyled>
        <Carousel
          autoPlay
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          interval={parameter.speedSlide}
          showArrows={false}
          stopOnHover={false}
          dynamicHeight={true}
        >
          {data.slid.map((elem, i) => (
            <section key={i}>
              <img src={`${process.env.PUBLIC_URL}${elem.img}`} alt="img" />
            </section>
          ))}
        </Carousel>

        <TypeContainer>
          <Typewriter
            loop
            cursor
            cursorStyle=""
            typeSpeed={70}
            deleteSpeed={40}
            delaySpeed={2000}
            words={["ARAB METALS", "Beyond Copper"]}
          />
        </TypeContainer>

        <Container>
          <Flex
            position={"absolute"}
            width={GlobalSwitch("change", display.screen, 1024, "25%", "85%")}
            top={"72%"}
            left={"auto"}
          >
            <Form action={email} method="POST">
              <input type="hidden" name="_captcha" value="true" />
              <Field type="hidden" name="_template" value="table" />
              <Field
                type="hidden"
                name="_autoresponse"
                value={data.FormulaireDeContact.newsletterRep}
              ></Field>
              <Field type="text" name="_honey" style={{ display: "none" }} />

              <Field
                type="email"
                name="Inscription Newsletters"
                required
                placeholder={data.FormulaireDeContact.newsletter}
              />
              <Send
                type="submit"
                opacity={"1"}
                width={"unset"}
                bg={"transparent"}
                border={"none"}
                padding={"0em 0em"}
                margin={"0em auto"}
                position={"absolute"}
                top={"0%"}
                right={"0%"}
                size={"2em"}
                borderHover={"none"}
                colorHover={"red"}
                bgHover={"transparent"}
                widthMob={"unset"}
              >
                <BsFillArrowRightCircleFill />
              </Send>
            </Form>
          </Flex>
        </Container>
      </HeaderStyled>
    </>
  );
};

export default Header;
