import styled from "styled-components";

export const TopContactStyled = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
`;
export const TopContactRow = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3em 0em;
  svg {
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.primaryOrange};
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 0.77em;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
      display: none;
    }
  }
  span {
    font-size: 1.2em;
    margin: 0em 1em;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 0.9em;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
      display: none;
    }
  }
`;
export const LanguageContent = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      display: none;
    }
`;
export const TopSocial = styled.section`
  width: 15%;
  display: none;
  align-items: center;
  justify-content: space-evenly;
  padding: 3em 0em 6em 0em;
  a {
    color: inherit;
  }
  svg {
    color: ${({ theme }) => theme.colors.primaryGray};
    font-size: 2em;
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    display: flex;
  }
`;
