import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import Topbarre from "./components/TopBarre";
import BottomBarre from "./components/BottomBarre";
import GlobalStyles from "./styles/global";
import { theme } from "./styles/theme";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Stats from "./components/Header/stats";
import { Container } from "./styles/container";
import { Routes, Route } from "react-router-dom";
import Products from "./components/products";
import Details from "./components/productDetails";
import CategoryCard from "./components/products/CategorieCard";
import Contact from "./components/Contact";
import Map from "./components/Map";
import { defaultSwitch, setFunction } from "./function";
import { fetchContent, fetchFlag, fetchParameter } from "./api";
import Panel from "./components/Languages/panel";
import LoadingNew from "./components/Loading";
import ErrorLang from "./components/errorLanguages";
const App = () => {
  const [content, setContent] = useState(false);
  const [flag, setFlag] = useState(false);
  const [parameter, setParameter] = useState(false);
  const [display, setDisplay] = useState({
    screen: window.innerWidth,
    scroll: window.pageYOffset,
    MenuSide: false,
    devisBtn: true,
    languagePanel: false,
    devisPanel: false,
    sharePanel: false,
    lang: "FR",
    load: true,
    loaderDisplay: true,
    error: null,
  });
  useEffect(() => {
    fetchContent(setContent, display, setDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display.lang]);
  useEffect(() => {
    fetchFlag(setFlag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchParameter(setParameter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const width = () => setFunction(setDisplay, "screen", window.innerWidth);
    window.removeEventListener("resize", width);
    window.addEventListener("resize", width, { passive: true });
    return () => window.removeEventListener("resize", width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  useEffect(() => {
    const onScroll = () =>
      setFunction(setDisplay, "scroll", window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setFunction(setDisplay, "load", false);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setFunction(setDisplay, "loaderDisplay", false);
    }, 7000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        overflow={defaultSwitch("change", display.load, "hidden", "unset")}
      />
      <LoadingNew display={display} />
      <>
        {content && parameter ? (
          <>
            <Topbarre
              setDisplay={setDisplay}
              display={display}
              data={content}
              parameter={parameter}
            />
            {flag ? (
              <Panel
                setDisplay={setDisplay}
                display={display}
                flag={flag}
                parameter={parameter}
                data={content}
              />
            ) : null}
            {display.error === 404 ? (
              <ErrorLang display={display} />
            ) : (
              <>
                <BottomBarre
                  setDisplay={setDisplay}
                  display={display}
                  data={content}
                />

                <Header
                  display={display}
                  data={content}
                  parameter={parameter}
                />
                <Container position={"relative"}>
                  <Stats display={display} data={content} />
                </Container>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Main
                        setDisplay={setDisplay}
                        display={display}
                        data={content}
                        flag={flag}
                        parameter={parameter}
                      />
                    }
                  />
                  <Route
                    path="/products"
                    element={
                      <Products
                        setDisplay={setDisplay}
                        display={display}
                        data={content}
                      />
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <>
                        <Contact
                          setDisplay={setDisplay}
                          display={display}
                          flag={flag}
                          parameter={parameter}
                          data={content}
                        />

                        <Map display={display} data={content} />
                      </>
                    }
                  />
                  <Route
                    path="/products/:producType"
                    element={<CategoryCard display={display} data={content} />}
                  />
                  <Route
                    path="/products/:producType/:productId"
                    element={
                      <Details
                        flag={flag}
                        data={content}
                        display={display}
                        setDisplay={setDisplay}
                        parameter={parameter}
                      />
                    }
                  />
                </Routes>
                <Footer
                  display={display}
                  setDisplay={setDisplay}
                  data={content}
                  parameter={parameter}
                />
              </>
            )}
          </>
        ) : null}
      </>
    </ThemeProvider>
  );
};

export default App;
