import React, { useState, useEffect } from "react";
import {
  Display,
  defaultSwitch,
  GlobalSwitch,
  setFunction,
} from "../../../function";
import Flex from "../../../styles/flex";
import { theme } from "../../../styles/theme";
import Phone from "../../Contact/SelectPhone";
import { Form, Input, Send } from "../../Main/Devis/style";
import { Title } from "../../Main/style";
import { DevisInput, DevisPanel } from "./style";
import { DeviByProductStyled } from "./style";
import { AiOutlineClose } from "react-icons/ai";
const DevisByProduct = ({ display, setDisplay, flag, data,product,parameter }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 40 : 650;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    setFunction(setDisplay, "devisBtn", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  const email = `https://formsubmit.co/${parameter.email}`;
  return (
    <DeviByProductStyled>
      <DevisPanel
        left={defaultSwitch("change", display.devisPanel, "51%", "0%")}
        opacity={defaultSwitch("change", display.devisPanel, "1", "0")}
        display={defaultSwitch("change", display.devisPanel, "block", "none")}
      >
        <Title padding={"1em 0em"} top={"2.5em"}>
          <Flex justify={"space-between"}>
            {data.Devis}
            <AiOutlineClose
              onClick={() => setFunction(setDisplay, "devisPanel", false)}
            />
          </Flex>
        </Title>
        <Form action={email} method="POST">
          <Input type="hidden" name="_template" value="table" />
          <Input
            type="hidden"
            name="_autoresponse"
            value={data.FormulaireDeContact.repAuto.part1 + product + data.FormulaireDeContact.repAuto.part2}
          ></Input>

          <DevisInput>
            <Input
              type="text"
              name="name"
              placeholder={data.FormulaireDeContact.nom}
              opacity={"1"}
              required
              width={"65%"}
              padding={".5em .5em"}
              bg={theme.colors.secondaryGray}
              border={theme.colors.primaryGray}
              color={theme.colors.primaryGray}
              margin=".5em auto"
            />
            <Input
              type="email"
              name="email"
              placeholder={data.FormulaireDeContact.email}
              opacity={"1"}
              required
              width={"65%"}
              padding={".5em .5em"}
              bg={theme.colors.secondaryGray}
              border={theme.colors.primaryGray}
              color={theme.colors.primaryGray}
              margin={".5em auto"}
            />
            <Phone
             data={data}
              setDisplay={setDisplay}
              display={display}
              bg={theme.colors.secondaryGray}
              color={theme.colors.primaryGray}
              opacity={"1"}
              width={"100%"}
              height={"10em"}
              parentWidth={GlobalSwitch(
                "change",
                display.screen,
                1024,
                "72%",
                "80%"
              )}
              padding={".5em .5em"}
              font={"1em"}
              top={"2%"}
              left={".5em"}
              margin={".5em auto"}
              widthList={"11.4em"}
              widthIcon={"26%"}
              animation={GlobalSwitch(
                "change",
                localScroll,
                Breakpoint,
                "animation:ApparitionBottom .5s forwards",
                null
              )}
              flag={flag}
            />
          </DevisInput>



          <DevisInput>
          <Input
              type="text"
              name="Quantité"
              value={product}
              opacity={"1"}
              required
              width={"65%"}
              padding={".5em .5em"}
              bg={theme.colors.secondaryGray}
              border={theme.colors.primaryGray}
              color={theme.colors.primaryGray}
              margin={".5em auto"}
            />
            <Input
              type="text"
              name="Quantité"
              placeholder={data.FormulaireDeContact.quantité}
              opacity={"1"}
              required
              width={"65%"}
              padding={".5em .5em"}
              bg={theme.colors.secondaryGray}
              border={theme.colors.primaryGray}
              color={theme.colors.primaryGray}
              margin={".5em auto"}
            />
            <Send
              opacity={"1"}
              padding={".5em .5em"}
              bg={theme.colors.secondaryGray}
                border={`1px solid${theme.colors.primaryGray}`}
                color={theme.colors.primaryGray}        
            >
            {data.DevisContent[2]}
            </Send>
            </DevisInput>
        </Form>
      </DevisPanel>
    </DeviByProductStyled>
  );
};

export default DevisByProduct;
