import React, { useEffect, useState } from "react";
import { CategoryCardStyled, NotFound } from "./style";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "../../../styles/container";
import Flex from "../../../styles/flex";
import Title from "../../common/title";
import { Display, GlobalSwitch } from "../../../function";
import { theme } from "../../../styles/theme";
import { Btn, Card, Image, Txt } from "../style";
import { BsArrowLeftCircle } from "react-icons/bs";
import noData from "../../../assets/not_found.svg";
import { Helmet } from "react-helmet-async";
const CategoryCard = ({ display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 240 : 246;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  const params = useParams();
  const disc = `Arab Metals met à votre disposition ses produits ${
    params.producType === "cuivre"
      ? data.products.refCuivre
      : data.products.refLaiton
  }`;
  const link = `/products/${params.producType}`;
  return (
    <>
      <Helmet>
        <title>Produits en {params.producType} ARAB METLAS</title>
        <meta name="description" content={disc} />
        <link rel="canonical" href={link} />
      </Helmet>

      <CategoryCardStyled>
        <Container>
          <Flex
            width={"100%"}
            direction={"column"}
            align={"flex-start"}
            padding={GlobalSwitch(
              "changeRev",
              display.screen,
              1024,
              "5em 0em 5em 0em",
              "10em 0em 5em 0em"
            )}
          >
            <Flex justify={"space-between"} width={"100%"} align={"flex-start"}>
              <Title
                txt={
                  params.producType === "laiton"
                    ? data.products.refLaiton
                    : data.products.refCuivre
                }
                align={display.lang === "AR" ? "end" : "start"}
                position={display.lang === "AR" ? "right:0;" : "left:0;"}
              />
              <Btn
                width={"10%"}
                size={"1.5em"}
                opacity={"0"}
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation: ApparitionLeft .5s forwards",
                  null
                )}
              >
                {" "}
                <Link
                  to={`/products`}
                  onClick={() => {
                    window.scrollTo(0, 740);
                  }}
                >
                  <BsArrowLeftCircle />
                </Link>
              </Btn>
            </Flex>

            <Flex
              padding={"2em 2em"}
              justify={"space-evenly"}
              width={"100%"}
              wrap={"wrap"}
            >
              {data.products.details.filter(
                (item) => item.type === params.producType
              ).length === 0 ? (
                <Flex width={"100%"} direction={"column"}>
                  <NotFound src={noData} alt="no-data" />
                  <span>{data.products.productData.noData}</span>
                </Flex>
              ) : (
                data.products.details
                  .filter((item) => item.type === params.producType)
                  .map((item, i) => (
                    <Link
                      to={`/products/${item.type}/${item.id}`}
                      onClick={() => {
                        window.scrollTo(0, 740);
                      }}
                    >
                      <Card
                        key={i}
                        width="80%"
                        opacity={"0"}
                        animation={GlobalSwitch(
                          "change",
                          localScroll,
                          Breakpoint,
                          "animation: ApparitionLeft .5s forwards",
                          null
                        )}
                      >
                        <Image
                          src={`${process.env.PUBLIC_URL}${item.img}`}
                          alt="cuivre"
                        />
                        <Flex
                          bg={theme.colors.secondaryGray}
                          justify={"space-evenly"}
                          width={"100%"}
                          border={"2em"}
                        >
                          <Txt>{item.title} </Txt>
                        </Flex>
                      </Card>
                    </Link>
                  ))
              )}
            </Flex>
          </Flex>
        </Container>
      </CategoryCardStyled>
    </>
  );
};

export default CategoryCard;
