/****Timport axios from "axios";


export const fetchActivation = async (setActivation) => {
  try {
    const response = await axios.get(`http://localhost:8000/data/activator`);

    if (!response || response.status !== 200) return setActivation(false);
    setActivation(response.data.result[0].status);
  } catch (error) {}
};
*************/

/***content function */
export const fetchContent = async (setContent, display, setDisplay) => {
  setDisplay((prevState) => ({
    ...prevState,
    error: null,
  }));
  try {
    const response = await fetch(
      `${process.env.PUBLIC_URL}/data/contents/${display.lang}.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          status: 200,
        },
      }
    );

    if (response.status !== 200) {
      setDisplay((prevState) => ({
        ...prevState,
        error: response.status,
      }));
      // setContent("Not available");
    } else {
      const data = await response.json();
      setContent(data);
    }
  } catch (error) {
    setDisplay((prevState) => ({
      ...prevState,
      error: error,
    }));
  }
};
export const fetchFlag = async (setFlag) => {
  try {
    const response = await fetch(
      `${process.env.PUBLIC_URL}/data/CountryCodes.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();
    setFlag(data);
  } catch (error) {
    console.log(error);
  }
};
export const fetchParameter = async (setParameter) => {
  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/data/params.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data = await response.json();
    setParameter(data);
  } catch (error) {
    console.log(error);
  }
};
