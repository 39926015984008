import React from "react";
import { Container } from "../../styles/container";
import { TopBarreStyled } from "./style";
import TopContact from "./TopContact";
const Topbarre = ({ setDisplay, display, data }) => {
  return (
    <TopBarreStyled>
      <Container>
        <TopContact setDisplay={setDisplay} display={display} data={data} />
      </Container>
    </TopBarreStyled>
  );
};

export default Topbarre;
