import styled from "styled-components";

export const CategoryCardStyled = styled.main`
  padding-top:3.7em;
  span {
    font-size: 2.5em;
    text-align: center;
  }
  a {
    text-decoration: none;
    display: flex;
justify-content: center;
  }
`;
export const NotFound = styled.img`
  width: 30%;
  margin-bottom: 4em;
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    width: 60%;
  }
`;
