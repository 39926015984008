import React from "react";
import { Container } from "../../styles/container";
import { ErrorStyled } from "./style";
import { Lang } from "../../function";
import Flex from "../../styles/flex";
import error from "../../assets/warning.svg";
const ErrorLang = ({ display }) => {
  return (
    <>
      <ErrorStyled>
        <Container>
          <Flex width={"100%"} direction={"column"}>
            <span>{Lang(display.lang)}</span>
            <img src={error} alt="error 404" />
          </Flex>
        </Container>
      </ErrorStyled>
    </>
  );
};

export default ErrorLang;
