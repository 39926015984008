import React from "react";
import Process from "../Process";
import About from "./about";
import Devis from "./Devis";
import OuResources from "./OurResources";
import { MainStyled } from "./style";
import { Helmet } from "react-helmet-async";
const Main = ({ setDisplay, display, data, flag, parameter }) => {
  return (
    <>
      <Helmet>
        <title>ARAB METLAS</title>
        <meta name="description" content="dic arabe metals" data-rh="true" />
        <link rel="canonical" href="/" />
        <meta property="og:title" content="ARAB METLAS" />
      </Helmet>
      <MainStyled>
        <About display={display} data={data} />
        <OuResources setDisplay={setDisplay} display={display} data={data} />
        <Process setDisplay={setDisplay} display={display} data={data} />
        <Devis
          setDisplay={setDisplay}
          display={display}
          data={data}
          flag={flag}
          parameter={parameter}
        />
      </MainStyled>
    </>
  );
};

export default Main;
