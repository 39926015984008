import styled from "styled-components";
export const AboutStyled = styled.section`
  background-color:${({ theme }) => theme.colors.secondaryGray}
`;
export const AboutSection = styled.section`
  flex: 1;
  opacity: 0;
  color: ${({ color }) => color || "inherit"};
  ${({ animation }) => animation};
  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding-bottom: 3em;
  }
  section {
    h1 {
      font-weight: 600;
    }
    font-size: 1.2em;
    text-align: justify;
    line-height: 35px;
    padding-left: 2em;
    color: ${({ theme, color }) => color || theme.colors.textColor};
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      padding-left: 0em;
    }
  }
`;

export const Picture = styled.section`
  flex: 1;
  display: flex;
  justify-content: ${({ justify }) => justify || "flex-end"};
  opacity: 0;
  ${({ animation }) => animation};

  @keyframes ApparitionRight {
    0% {
      opacity: 0;
      transform: translateX(550px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding-bottom: 3em;
  }
`;
export const Border = styled.section`
  width: 28em;
  height: 28em;
  border: 7px solid ${({ theme }) => theme.colors.primaryOrange};
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    width: 15em;
    height: 16em;
  }
`;
export const Img = styled.img`
  position: absolute;
  width: 28em;
  top: 0.5em;
  left: 0.5em;
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    width: 15em;
  }
`;

export const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  padding: ${({ padding }) => padding || "2em 0em"};
  position: relative;
  color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity || "1"};
  ${({ animation }) => animation};
  flex: ${({ flex }) => flex || "unset"};
  svg {
    cursor: pointer;
    &:hover {
      color: tomato;
    }
  }

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    font-size: 1.1em;
  }
  &:before {
    content: "";
    width: 2em;
    height: 0.2em;
    position: absolute;
    top: ${({ top }) => top || "3.5em"};
    left: 0;
    background-color: ${({ bgcolor, theme }) =>
      bgcolor || theme.colors.primaryGray};
    transform-origin: left top;
    transform: scale(1, 1);
  }
`;
