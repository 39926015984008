import logo from "../../assets/load.png";
import styled from "styled-components";

export const LoadNewStyled = styled.section`
  width: 100vw;
  height: 100vh;
  display: ${({ display }) => display};
  background-color: ${({ theme }) => theme.colors.primaryFooter};
  animation: ${({ animation }) => animation};
  @keyframes slid {
    0% {
      height: 100vh;
    }
    100% {
      height: 0vh;
    }
  }
`;
export const Slider = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 2em;
    color: ${({ theme }) => theme.colors.primaryWhite};
    display: inline-block;
    letter-spacing: 2px;
    box-sizing: border-box;
    animation: spotlight 2s linear infinite alternate;
    @keyframes spotlight {
  0% , 20% {
    opacity: 1;
    letter-spacing: 2px;
   }
 80% , 100% {
    opacity: 0;
    letter-spacing: 15px;
   }
}
  }
  animation: ${({ animation }) => animation};
  @keyframes disappear {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;
export const Loader = styled.section`
  position: relative;
  width: 88px;
  height: 79px;
  background-image: url(${logo});
  margin-bottom: 2em;
  animation: animate 5s ease-in-out infinite;

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(45deg);
    }
    30% {
      transform: rotate(90deg);
    }

    40% {
      transform: rotate(180deg);
    }
    50% {
      transform: rotate(270deg);
    }
    60% {
      transform: rotate(180deg);
    }
    70% {
      transform: rotate(90deg);
    }
    80% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
