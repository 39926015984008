import React, { useState, useEffect } from "react";
import Title from "../../common/title";
import {
  DeviStyled,
  Form,
  Infos,
  Input,
  ListSelect,
  Select,
  Send,
} from "./style";
import { theme } from "../../../styles/theme";
import { Container } from "../../../styles/container";
import Flex from "../../../styles/flex";
import { GoTriangleDown } from "react-icons/go";
import {
  openSelect,
  GlobalSwitch,
  Display,
  setFunction,
} from "../../../function";
import Phone from "../../Contact/SelectPhone";
const Devis = ({ setDisplay, display, data, flag, parameter }) => {
  const [select, setSelect] = useState("");
  const [localScroll, setlocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 6535 : 4010;
  useEffect(() => {
    Display(setlocalScroll, Breakpoint);
    setFunction(setDisplay, "devisBtn", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  const [open, setOpen] = useState(false);
  const email = `https://formsubmit.co/${parameter.email}`;
  return (
    <DeviStyled id="devis">
      <Container>
        <Title
          txt={data.DevisContent[0]}
          color={theme.colors.primaryGray}
          align={display.lang === "AR" ? "end" : "start"}
          position={display.lang === "AR" ? "right:0;" : "left:0;"}
        />
        <Flex width={"100%"} padding={"5em 0em 12em 0em"}>
          <Form action={email} method="POST">
            <Input type="hidden" name="_template" value="table" />
            <Input
              type="hidden"
              name="_autoresponse"
              value={
                data.FormulaireDeContact.repAuto.part1 +
                select +
                data.FormulaireDeContact.repAuto.part2
              }
            ></Input>
            <Input type="text" name="_honey" style={{ display: "none" }} />
            <Infos>
              <Input
                type="text"
                name="name"
                placeholder={data.FormulaireDeContact.nom}
                required
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation:ApparitionBottom .5s forwards",
                  null
                )}
              />
              <Input
                type="email"
                name="email"
                placeholder={data.FormulaireDeContact.email}
                required
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation:ApparitionBottom .5s forwards",
                  null
                )}
              />
              <Phone
                data={data}
                setDisplay={setDisplay}
                display={display}
                color={theme.colors.primaryGray}
                width={"92%"}
                parentWidth={GlobalSwitch(
                  "change",
                  display.screen,
                  1024,
                  "59%",
                  "82%"
                )}
                height={"9em"}
                widthList={"100%"}
                widthIcon={"20%"}
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation:ApparitionBottom .5s forwards",
                  null
                )}
                flag={flag}
              />
            </Infos>
            <Infos>
              <Input
                type="text"
                name="product"
                required
                value={select}
                style={{ display: "none" }}
              />
              <Select
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation:ApparitionBottom .5s forwards",
                  null
                )}
              >
                <span onClick={() => setOpen(open ? false : true)}>
                  {select ? select : <>{data.DevisContent[1]}</>}
                </span>
                <GoTriangleDown onClick={() => setOpen(open ? false : true)} />
                <ListSelect display={openSelect(open)}>
                  {data.products.details.map((elem, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        setSelect(elem.title);
                        setOpen(open ? false : true);
                      }}
                    >
                      {elem.title}
                    </li>
                  ))}
                </ListSelect>
              </Select>
              <Input
                type="text"
                name="Quantité"
                placeholder={data.FormulaireDeContact.quantité}
                required
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation:ApparitionBottom .5s forwards",
                  null
                )}
              />
              <Send
                type="submit"
                bg={theme.colors.secondaryGray}
                border={`1px solid${theme.colors.primaryGray}`}
                color={theme.colors.primaryGray}
                animation={GlobalSwitch(
                  "change",
                  localScroll,
                  Breakpoint,
                  "animation:ApparitionBottom .5s forwards",
                  null
                )}
              >
                <span>{data.DevisContent[2]}</span>
              </Send>
            </Infos>
          </Form>
        </Flex>
      </Container>
    </DeviStyled>
  );
};

export default Devis;
