import React, { useEffect, useState } from "react";
import { Card, Image, ProductStyled, Txt } from "./style";
import { Container } from "../../styles/container";
import Title from "../common/title";
import Flex from "../../styles/flex";
import { Link } from "react-router-dom";
import { theme } from "../../styles/theme";
import { Display, GlobalSwitch, setFunction } from "../../function";
import { Helmet } from "react-helmet-async";
const Products = ({ setDisplay, display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 240 : 246;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    setFunction(setDisplay, "devisBtn", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  return (
    <>
      <Helmet>
        <title>PRODUITS ARAB METLAS</title>
        <meta
          name="description"
          content="Arab Metals met à votre disposition les  
        différentes catégories de produits disponibles à la vente
        "
        />
        <link rel="canonical" href="/products" />
      </Helmet>
      <ProductStyled>
        <Container>
          <Flex
            direction={"column"}
            align={"flex-start"}
            padding={GlobalSwitch(
              "changeRev",
              display.screen,
              1024,
              "5em 0em 5em 0em",
              "10em 0em 5em 0em"
            )}
          >
            <Title txt={data.products.title}align={display.lang === "AR" ? "end" : "start"}
              position={display.lang === "AR" ? "right:0;" : "left:0;"} />
            <Flex
              padding={"2em 2em"}
              justify={"space-evenly"}
              width={"100%"}
              position="relative"
              direction={GlobalSwitch(
                "changeRev",
                display.screen,
                1024,
                "column",
                null
              )}
            >
              <Link to={`/products/cuivre`}>
                <Card
                  width="80%"
                  opacity={"0"}
                  animation={GlobalSwitch(
                    "change",
                    localScroll,
                    Breakpoint,
                    "animation: ApparitionLeft .5s forwards",
                    null
                  )}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}${data.products.productData.cuivre}`}
                    alt="cuivre"
                  />
                  <Flex
                    bg={theme.colors.secondaryGray}
                    justify={"space-evenly"}
                    width={"100%"}
                    border={"2em"}
                  >
                    <Txt>{data.prodCui}</Txt>
                  </Flex>
                </Card>{" "}
              </Link>
              <Link to={`/products/laiton`}>
                <Card
                  width="80%"
                  opacity={"0"}
                  animation={GlobalSwitch(
                    "change",
                    localScroll,
                    Breakpoint,
                    "animation: ApparitionLeft .5s forwards",
                    null
                  )}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}${data.products.productData.laiton}`}
                    alt="laiton"
                  />
                  <Flex
                    bg={theme.colors.secondaryGray}
                    justify={"space-evenly"}
                    width={"100%"}
                    border={"2em"}
                  >
                    <Txt>{data.prodLait}</Txt>
                  </Flex>
                </Card>
              </Link>
            </Flex>
          </Flex>
        </Container>
      </ProductStyled>
    </>
  );
};

export default Products;
