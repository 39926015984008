import React, { useEffect, useState } from "react";
import {
  DetailBoxStyled,
  DetailImg,
  DetailStyled,
  DetailText,
  Action,
  OpenDevis,
} from "./style";
import { useParams } from "react-router-dom";
import { Display, GlobalSwitch, setFunction } from "../../function";
import Flex from "../../styles/flex";
import Title from "../common/title";
import { Container } from "../../styles/container";
import DevisByProduct from "./DevisByProduct";
import { MdDownload, MdShare } from "react-icons/md";
import { BsArrowLeftCircle } from "react-icons/bs";
import SharePanel from "./DevisByProduct/sharePanel";
import { theme } from "../../styles/theme";
import { Btn } from "../products/style";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider, HelmetData } from "react-helmet-async";
const Details = ({ display, setDisplay, data, flag, parameter }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 240 : 246;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  const params = useParams();
  const disc = data.products.details.filter(
    (item) => item.id === params.productId
  );
  const helmetData = new HelmetData({});
  const link = `/products/${params.producType}/${params.productId}`;
  return (
    <HelmetProvider>
      <Helmet helmetData={helmetData}>
        <title>{params.productId}</title>
        <meta name="description" content={disc[0].dis} data-rh="true" />
        <meta property="og:title" content="teste title" />
        <meta property="og:description" content="teste description" />
        <meta property="og:type" content="product" />{" "}
        <link rel="canonical" href={link} />
      </Helmet>
      <DetailStyled>
        <Container>
          {data.products.details
            .filter((item) => item.id === params.productId)
            .map((item, i) => (
              <Flex
                direction={"column"}
                align={"flex-start"}
                padding={GlobalSwitch(
                  "changeRev",
                  display.screen,
                  1024,
                  "5em 0em 7em 0em",
                  "10em 0em 7em 0em"
                )}
              >
                <Flex
                  justify={"space-between"}
                  width={"100%"}
                  align={"flex-start"}
                >
                  <Title
                    txt={item.title}
                    align={display.lang === "AR" ? "end" : "start"}
                    position={display.lang === "AR" ? "right:0;" : "left:0;"}
                  />
                  <Btn
                    opacity={"0"}
                    animation={GlobalSwitch(
                      "change",
                      localScroll,
                      Breakpoint,
                      "animation: ApparitionLeft .5s forwards",
                      null
                    )}
                  >
                    <Link
                      to={`/products/${item.type}`}
                      onClick={() => {
                        window.scrollTo(0, 740);
                      }}
                    >
                      <BsArrowLeftCircle />
                    </Link>
                  </Btn>
                </Flex>
                <DetailBoxStyled
                  key={i}
                  opacity={"0"}
                  animation={GlobalSwitch(
                    "change",
                    localScroll,
                    Breakpoint,
                    "animation: ApparitionLeft .5s forwards",
                    null
                  )}
                >
                  <DetailImg
                    src={`${process.env.PUBLIC_URL}${item.img}`}
                    alt={item.id}
                  />
                  <DevisByProduct
                    flag={flag}
                    display={display}
                    setDisplay={setDisplay}
                    data={data}
                    product={item.title}
                    parameter={parameter}
                  />
                  <DetailText>
                    <Flex
                      width={"100%"}
                      align={"center"}
                      justify={"flex-start"}
                      padding={"0em 0em 1.5em 0em"}
                    >
                      <Action
                        opacity={"0"}
                        animation={GlobalSwitch(
                          "change",
                          localScroll,
                          Breakpoint,
                          "animation: ApparitionLeft .5s forwards",
                          null
                        )}
                      >
                        <a
                          href={`${process.env.PUBLIC_URL}/doc/${item.doc}.pdf`}
                          download={`${item.doc}.pdf`}
                        >
                          <MdDownload />
                        </a>
                      </Action>
                      <Action
                        opacity={"0"}
                        animation={GlobalSwitch(
                          "change",
                          localScroll,
                          Breakpoint,
                          "animation: ApparitionLeft .5s forwards",
                          null
                        )}
                        selected={
                          display.sharePanel
                            ? `background-color: ${theme.colors.primaryOrange}; color: ${theme.colors.primaryWhite}; border-radius: 100px; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`
                            : null
                        }
                      >
                        <MdShare
                          onClick={() =>
                            setFunction(
                              setDisplay,
                              "sharePanel",
                              display.sharePanel ? false : true
                            )
                          }
                        />{" "}
                        <SharePanel display={display} item={item} />
                      </Action>
                    </Flex>
                    <Flex
                      width={"100%"}
                      align={"center"}
                      justify={"space-between"}
                      padding={"0em 0em 1.5em 0em"}
                    >
                      <h1>{item.title}</h1>
                      <OpenDevis
                        bg={theme.colors.secondaryGray}
                        border={`1px solid${theme.colors.primaryGray}`}
                        color={theme.colors.primaryGray}
                        onClick={() =>
                          setFunction(setDisplay, "devisPanel", true)
                        }
                      >
                        {data.btnDevis}
                      </OpenDevis>
                    </Flex>
                    <p
                      style={{
                        textAlign: display.lang === "AR" ? "end" : "start",
                      }}
                    >
                      {item.dis}
                    </p>
                  </DetailText>
                </DetailBoxStyled>{" "}
              </Flex>
            ))}
        </Container>
      </DetailStyled>
    </HelmetProvider>
  );
};

export default Details;
