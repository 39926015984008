import React, { useEffect, useState } from "react";
import { Card, ImgCard, OuResourceStyled, Paragraph } from "./style";
import Flex from "../../../styles/flex";
import { Container } from "../../../styles/container";
import { GlobalSwitch, Display } from "../../../function";
import Title from "../../common/title";
const OuResources = ({ display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 1400 : 800;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  return (
    <OuResourceStyled
      animation={GlobalSwitch(
        "change",
        localScroll,
        Breakpoint,
        "animation: fadeIn .5s forwards",
        null
      )}
    >
      <Container>
        <Title
          txt={data.ouResourcesTitle}
          align={display.lang === "AR" ? "end" : "start"}
          position={display.lang === "AR" ? "right:0;" : "left:0;"}
        />
        <Flex
          width={"100%"}
          justify={"space-evenly"}
          padding={"2em 0em 6em 0em"}
          align="flex-start"
          direction={GlobalSwitch(
            "changeRev",
            display.screen,
            1024,
            "column",
            null
          )}
        >
          {data.ouResources.map((elem, i) => (
            <Card key={i}>
              <ImgCard
                src={`${process.env.PUBLIC_URL}${elem.img}`}
                alt="labo-image"
              />
              <h3>{elem.title}</h3>
              <Paragraph padding={i === 1 ? "1em 3em" : "1em 2em"}>
                {elem.txt}
              </Paragraph>
            </Card>
          ))}
        </Flex>
      </Container>
    </OuResourceStyled>
  );
};

export default OuResources;
