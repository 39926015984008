import styled from "styled-components";

const Flex = styled.div`
  width: ${({ width }) => width || "unset"};
  height: ${({ height }) => height || "unset"};
  display: flex;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "unset"};
  flex-direction: ${({ direction }) => direction || "row"};
  flex-wrap: ${({ wrap }) => wrap || "unset"};
  position: ${({ position }) => position || "unset"};
  top: ${({ top }) => top || "unset"};
  left: ${({ left }) => left || "unset"};
  padding: ${({ padding }) => padding || "unset"};
  background-color: ${({ bg }) => bg || "transparent"};
  box-shadow: ${({ shadow }) => shadow || "unset"};
  border-radius:${({ border }) => border || "unset"};
`;
export default Flex;
