import React from "react";
import Flex from "../../styles/flex";
import {
  Center,
  FooterStyled,
  Info,
  LogoFooter,
  InfoContent,
  Newsletters,
  SocialFooter,
  Copyright,
  MenuList,
  ContactList,
} from "./style";
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineFieldTime,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { theme } from "../../styles/theme";
import { Field } from "../Header/style";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import Logo from "../../assets/Logo-Footer.png";
import { GlobalSwitch, setFunction } from "../../function";
import { Container } from "../../styles/container";
import { Form, Send } from "../Main/Devis/style";
import Title from "../common/title";
const Footer = ({ setDisplay, display, data, parameter }) => {
  const email = `https://formsubmit.co/${parameter.emailNewsletters}`;
  return (
    <FooterStyled>
      <Container>
        <Flex
          width={"100%"}
          padding={"5em 0em 3em 0em"}
          justify={"space-evenly"}
          direction={GlobalSwitch(
            "changeRev",
            display.screen,
            1024,
            "column-reverse",
            null
          )}
        >
          <Newsletters>
            <p>{data.footer.catch}</p>
            <Flex position={"relative"} width={"70%"}>
              <Form action={email} method="POST">
                <Field type="hidden" name="_template" value="table" />
                <Field
                  type="hidden"
                  name="_autoresponse"
                  value={data.FormulaireDeContact.repAuto.newsletter}
                ></Field>
                <Field type="text" name="_honey" style={{ display: "none" }} />
                <Field
                  type="email"
                  required
                  placeholder={data.FormulaireDeContact.newsletter}
                />
                <Send
                  type="submit"
                  opacity={"1"}
                  width={"unset"}
                  bg={"transparent"}
                  border={"none"}
                  padding={"0em 0em"}
                  margin={"0em auto"}
                  position={"absolute"}
                  top={"0%"}
                  right={"0%"}
                  size={"2em"}
                  borderHover={"none"}
                >
                  <BsFillArrowRightCircleFill />
                </Send>
              </Form>
            </Flex>
          </Newsletters>
          <Info>
            <Center>
              <LogoFooter src={Logo} alt="Logo-footer" />
              <SocialFooter>
                <a
                  href="https://www.facebook.com/arabmetalsalgerie/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare />
                </a>
                <a
                  href="https://www.linkedin.com/company/arab-metals-spa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a href="##">
                  <FaTwitterSquare />
                </a>
              </SocialFooter>
            </Center>
            <Center direction={"row"} align={"flex-start"}>
              <InfoContent>
                <Title
                  txt={data.footer.menu}
                  align={display.lang === "AR" ? "end" : "start"}
                />
                <MenuList
                  mobile={
                    "width:100%;display:flex;justify-content:space-evenly;padding:1.5em 0em"
                  }
                  direction="column"
                >
                  <li>
                    <NavLink
                      to="/"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setFunction(setDisplay, "devisBtn", true);
                        setFunction(setDisplay, "MenuSide", false);
                      }}
                    >
                      {data.menu[0]}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/products"
                      onClick={() => {
                        window.scrollTo(0, 760);
                        setFunction(setDisplay, "devisBtn", false);
                        setFunction(setDisplay, "MenuSide", false);
                      }}
                    >
                      {" "}
                      {data.menu[1]}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      onClick={() => {
                        window.scrollTo(0, 760);
                        setFunction(setDisplay, "devisBtn", false);
                        setFunction(setDisplay, "MenuSide", false);
                      }}
                    >
                      {data.menu[2]}
                    </NavLink>
                  </li>
                  {display.devisBtn ? (
                    <li onClick={() => window.scrollTo(0, 2075)}>
                      {data.devis}
                    </li>
                  ) : null}
                </MenuList>
              </InfoContent>
              <InfoContent
                border={`1px solid ${theme.colors.primaryWhite}`}
                padding={GlobalSwitch(
                  "changeRev",
                  display.screen,
                  1024,
                  "0em 2em 0em 2em",
                  "0em 3em 0em 2em"
                )}
              >
                <Title
                  txt={data.footer.contact}
                  align={display.lang === "AR" ? "end" : "start"}
                />
                <ul>
                  <ContactList
                    direction={display.lang === "AR" ? "row-reverse" : "row"}
                    padding={
                      display.lang === "AR"
                        ? "0em 0em 0em 1em"
                        : "0em 1em 0em 0em"
                    }
                    align={display.lang === "AR" ? "end" : "start"}
                  >
                    <AiOutlinePhone />
                    <p>+213 770 65 94 87</p>
                  </ContactList>
                  <ContactList
                    direction={display.lang === "AR" ? "row-reverse" : "row"}
                    padding={
                      display.lang === "AR"
                        ? "0em 0em 0em 1em"
                        : "0em 1em 0em 0em"
                    }
                    align={display.lang === "AR" ? "end" : "start"}
                  >
                    <AiOutlineMail />
                    <p>contact@arab-metals.com</p>
                  </ContactList>
                  <ContactList
                    direction={display.lang === "AR" ? "row-reverse" : "row"}
                    padding={
                      display.lang === "AR"
                        ? "0em 0em 0em 1em"
                        : "0em 1em 0em 0em"
                    }
                    align={display.lang === "AR" ? "end" : "start"}
                  >
                    <AiOutlineFieldTime />
                    <p>{data.time} 08:00 / 17:00</p>
                  </ContactList>
                </ul>
              </InfoContent>
            </Center>
          </Info>
        </Flex>{" "}
      </Container>

      <Copyright>
        <Container>
          <Flex width={"100%"} justify={"center"}>
            <span>
              {" "}
              Copyright © {new Date().getFullYear()} ARABE METALS -{" "}
              {data.footer.copyright} | Powered by
              <a href="https://www.esw.dz" target="_blank" rel="noreferrer">
                Elemental Smart Web
              </a>
            </span>
          </Flex>
        </Container>
      </Copyright>
    </FooterStyled>
  );
};

export default Footer;
