import { SiOxygen } from "react-icons/si";
import { GiElectric } from "react-icons/gi";
import { FaCompressArrowsAlt } from "react-icons/fa";
import { RiRulerLine, RiTestTubeLine } from "react-icons/ri";
/*****UI responsive functions******/

export const GlobalSwitch = (change, current, position, parms, first) => {
  const changer = {
    change: () => (current >= position ? parms : first),
    changeRev: () => (current <= position ? parms : first),
    TestId: () => (current % position ? parms : first),
  };
  return changer[(current, position, parms, first, change)];
};
export const defaultSwitch = (change, current, parms, first) => {
  const changer = {
    change: () => (current ? parms : first),
  };
  return changer[(current, parms, first, change)];
};
export const OpenList = (open) => {
  return open ? "flex" : "none";
};
export const openSelect = (open) => {
  return open ? "flex" : "none";
};
/**************One time animation in scroll function*****************/
export const Display = (setLocalScroll, position) => {
  if (window.pageYOffset > position) {
    setLocalScroll(window.pageYOffset);
  }
};
export const DisplayPhone = (setPhone, position) => {
  if (window.pageYOffset > position) {
    setPhone((prevState) => ({
      ...prevState,
      localScroll: window.pageYOffset,
    }));
  }
};

/***********************SetDisplay function****************************/
export const setFunction = (set, key, value) => {
  set((prevState) => ({
    ...prevState,
    [`${key}`]: value,
  }));
};

/***************Icons function ************************/
export const Icons = (icon) => {
  const selector = {
    Oxygen: <SiOxygen />,
    Chimi: <RiTestTubeLine />,
    elec: <GiElectric />,
    traction: <FaCompressArrowsAlt />,
    diametre: <RiRulerLine />,
  };
  return selector[icon];
};
/***************Lang function ************************/
export const Lang = (query) => {
  const selector = {
    EN: "English is not yet available ! select another language",
    FR: "Le Français n'est encore disponible ! sélectionnez une autre langue",
    AR: "اللغة العربية ليست متوفرة بعد! اختر لغة أخرى",
  };
  return selector[query];
};
