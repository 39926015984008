import styled from "styled-components";

export const LanguagePanel = styled.section`
  position: fixed;
  top: 9%;
  right: 8.5%;
  width: 8em;
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 5px;
  z-index: 4;
  display: flex;
  ul {
    width: 100%;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 1em;
  }
  img {
    width: 30%;
    cursor: pointer;
    margin-right: 5%;
    border: 0.5px solid black;
  }
  span {
    color: inherit;
    font-size: 1.1em;
    cursor: pointer;
  }
`;
