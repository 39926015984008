import styled from "styled-components";

export const ErrorStyled = styled.section`
  span {
    font-size: 1.5em;
    font-weight: 600;
    padding: 3em 0em;
  }
  img {
    width: 30%;
  }
`;
