import styled from "styled-components";
import bg from "../../assets/process.jpg";
export const ProceStyled = styled.section`
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-family: inherit;
`;
export const Ligne = styled.section`
  width: 100%;
  min-height: 500px;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-blend-mode: overlay;
  background-color: ${({ theme }) => theme.colors.overlay};
  padding: 5em 0em;
`;

export const ProcessTxt = styled.p`
  width: 100%;
  padding: 0em 5em;
  font-size: 2em;
  line-height: 45px;
  text-align: justify;
  opacity: 0;
  ${({ animation }) => animation};
  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding: 0em 1em;
    font-size: 1.5em;
    text-align: center;
  }
`;
export const Control = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryGray};
  padding: 5em 0em;
`;
