export const theme = {
  colors: {
    primaryGray: "#1c232b",
    secondaryGray: "#ffff",
    primaryOrange: "#f78513",
    primaryWhite: "#f8f8f8",
    primaryFooter: "#181818",
    textColor: "#686868",
    overlay: "#2b2b2b",
  },
  breakPoint: {
    desktop: "1200px",
    tab: "1024px",
    mobile: "600px",
  },
};
