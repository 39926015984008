import React, { useState, useEffect } from "react";
import {
  DisplayPhone,
  GlobalSwitch,
  OpenList,
  setFunction,
} from "../../../function";
import {
  Dial,
  Flag,
  FlagSelect,
  InputPhone,
  ListSelect,
  PhoneStyled,
} from "./style";
import { GoTriangleDown } from "react-icons/go";
import { MdOutlineDialerSip } from "react-icons/md";
import { theme } from "../../../styles/theme";
const Phone = ({
  setDisplay,
  display,
  opacity,
  padding,
  bg,
  color,
  width,
  widthList,
  height,
  font,
  top,
  left,
  parentWidth,
  animation,
  margin,
  widthIcon,
  flag,
  data,
}) => {
  const [phone, setPhone] = useState({
    dial: "",
    number: "",
    open: false,
    localScroll: 0,
  });
  const Breakpoint = display.screen <= theme.breakPoint.tab ? 40 : 650;
  useEffect(() => {
    DisplayPhone(setPhone, Breakpoint);
    setFunction(setDisplay, "devisBtn", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  const numbers = /^[+]{0,1}[0-9]{1,4},{0,1}[\s./0-9]*$/g;
  const onChange = (e) => {
    if (numbers.test(e.target.value)) {
      setFunction(
        setPhone,
        "number",
        phone.number === ""
          ? phone.dial.dial_code + " " + e.target.value
          : e.target.value
      );
    }
  };

  return (
    <PhoneStyled width={parentWidth} animation={animation} opacity={opacity}>
      <InputPhone
        style={{ pointerEvents: phone.dial === "" ? "none" : null }}
        type="text"
        name="Phone"
        maxLength={14}
        value={phone.number}
        placeholder={
          phone.dial === ""
            ? data.FormulaireDeContact.indica
            : data.FormulaireDeContact.num
        }
        onChange={(e) => onChange(e)}
        required
        animation={GlobalSwitch(
          "change",
          phone.localScroll,
          Breakpoint,
          "animation:ApparitionBottom .5s forwards",
          null
        )}
        bg={bg}
        color={color}
        width={width}
        padding={padding}
        margin={margin}
      />
      <Dial
        animation={GlobalSwitch(
          "change",
          phone.localScroll,
          Breakpoint,
          "animation:ApparitionBottom .5s forwards",
          null
        )}
        bg={bg}
        color={color}
        font={font}
        top={top}
        left={left}
        margin={margin}
        widthIcon={widthIcon}
      >
        <Flag
          color={color}
          onClick={() => {
            setFunction(setPhone, "open", phone.open ? false : true);
          }}
        >
          {phone.dial ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/flags/4x3/${phone.dial.code}.svg`}
                alt="flag"
              />{" "}
              <GoTriangleDown />
            </>
          ) : (
            <>
              <MdOutlineDialerSip /> <GoTriangleDown />
            </>
          )}
        </Flag>
      </Dial>
      {flag ? (
        <ListSelect
          display={OpenList(phone.open)}
          color={color}
          height={height}
          width={widthList}
        >
          {flag.map((elem, i) => (
            <FlagSelect
              key={i}
              onClick={() => {
                setFunction(setPhone, "number", "");
                setFunction(setPhone, "open", phone.open ? false : true);
                setFunction(setPhone, "dial", elem);
              }}
              bg={bg}
              color={color}
            >
              <img
                src={`${process.env.PUBLIC_URL}/flags/4x3/${elem.code}.svg`}
                alt="flag"
              />
              {elem.name} {elem.dial_code}
            </FlagSelect>
          ))}
        </ListSelect>
      ) : null}
    </PhoneStyled>
  );
};

export default Phone;
