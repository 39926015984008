import React from "react";
import {
  LanguageContent,
  TopContactRow,
  TopContactStyled,
  TopSocial,
} from "./style";
import {
  MdOutlinePhoneAndroid,
  MdMailOutline,
  MdOutlineAccessTime,
} from "react-icons/md";
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import Flex from "../../../styles/flex";
import Language from "../../Languages";
import { GlobalSwitch } from "../../../function";
const TopContact = ({ setDisplay, display, data, parameter }) => {
  return (
    <TopContactStyled>
      <Flex
        justify={GlobalSwitch(
          "change",
          display.screen,
          600,
          "space-between",
          "center"
        )}
        position={"relative"}
      >
        <Flex
          justify={"center"}
          width={GlobalSwitch("change", display.screen, 600, "unset", "100%")}
        >
          <TopSocial>
            <a
              href="https://www.facebook.com/arabmetalsalgerie/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare />
            </a>
          </TopSocial>
          <TopSocial>
            <a
              href="https://www.linkedin.com/company/arab-metals-spa/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FaLinkedin />
            </a>
          </TopSocial>
          <TopSocial>
            <a href="##">
              <FaTwitterSquare />
            </a>
          </TopSocial>
          <TopContactRow>
            <MdOutlinePhoneAndroid />
            <span>+213 770 65 94 87</span>
          </TopContactRow>
          <TopContactRow>
            <MdMailOutline />
            <span>contact@arab-metals.com</span>
          </TopContactRow>
          <TopContactRow>
            <MdOutlineAccessTime />
            <span>{data.time} 08:00 / 17:00</span>
          </TopContactRow>
        </Flex>

        <LanguageContent>
          <Language
            setDisplay={setDisplay}
            display={display}
            parameter={parameter}
          />
        </LanguageContent>
      </Flex>
    </TopContactStyled>
  );
};

export default TopContact;
