import styled from "styled-components";
export const HeaderStyled = styled.header`
  position: relative;
  height: 37em;
  opacity: 0;
  font-family: "Barlow", sans-serif;
  animation: Apparition 0.1s forwards;
  @keyframes Apparition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  svg {
    position: ${({ position }) => position || "relative"};
    z-index: 1;
    top: 0.1em;
    right: 0.1em;
    color: ${({ theme }) => theme.colors.primaryGray};
    font-size: 3em;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryGray};
    }
    img {
      height: 37em !important;
      object-fit: cover !important;
    }
  }
  p {
    position: absolute;
    left: auto !important;
    right: auto !important;
    bottom: 0 !important;
    width: 100% !important;
    background: transparent !important;
    color: #fff;
    padding: 10px;
    font-size: 4em !important;
    font-weight: 600;
    text-align: left !important;
    opacity: 0.25;
    top: 25% !important;
    opacity: 1 !important;
    margin-left: 0 !important;
    padding: 0em 3em !important;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      padding: 0% 2% !important;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
      font-size: 2.5em !important;
      top: 30% !important;
    }
  }
  span {
    position: absolute;
    top: 25%;
    z-index: 999;
    color: white;
    margin: auto auto;
    width: 100%;
    font-size: 4em;
    padding: 0em 5em 0em 3.3em;
    text-align: left;
    font-weight: 500;
    @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
      font-size: 3em;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      top: 20%;
      text-align: center;
      padding: 0em 1em 0em 1em;
    }
  }
`;
export const Field = styled.input`
  color: ${({ theme }) => theme.colors.primaryGray};
  background-color: white;
  border: 2px solid ${({ theme }) => theme.colors.primaryWhite};
  outline: none;
  text-indent: 1em;
  width: 100%;
  position: relative;
  z-index: 1;
  border-radius: 50px;
  padding: 1.5em 0em;
  cursor: pointer;
  transition: 0.2s ease-in;
  ::placeholder {
    color: ${({ theme }) => theme.colors.primaryGray};
  }
`;
export const TypeContainer = styled.section`
  background-color: transparent;
  width: 100%;
  height: 70%;
  position: absolute;
  top: 15%;
  display: flex;
  font-size: 2em;
  justify-content: center;
  padding: 1em 6em;
  font-weight: 600;
  font-family: "Rowdies", cursive;
  span {
    color: white;
    width: max-content;
    padding: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    font-size: .8em;
    }
`;
