import styled from "styled-components";

export const OuResourceStyled = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  opacity: 0;
  padding-top: 3em;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  ${({ animation }) => animation};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding-bottom: 3em;
  }
`;

export const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  padding: ${({ padding }) => padding || "2em 0em"};
  position: relative;
  color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity || "1"};
  ${({ animation }) => animation};
  flex: ${({ flex }) => flex || "unset"};
  svg {
    cursor: pointer;
    &:hover {
      color: tomato;
    }
  }

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    font-size: 1.1em;
  }
  &:before {
    content: "";
    width: 2em;
    height: 0.2em;
    position: absolute;
    top: ${({ top }) => top || "3.5em"};
    left: 0;
    background-color: ${({ bgcolor, theme }) =>
      bgcolor || theme.colors.primaryGray};
    transform-origin: left top;
    transform: scale(1, 1);
  }
`;

export const Card = styled.section`
  background-color: ${({ theme }) => theme.colors.secondaryGray};
  border-bottom: 3px solid ${({ theme }) => theme.colors.primaryOrange};
  width: 28%;
  height: 30em;
  border-radius: 1em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.2s ease-in;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
    height: auto;
    margin-bottom: 3em;
  }
  h3 {
    font-size: 1.3vw;
    font-weight: 600;
    padding: 1em 0em;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.breakPoint.desktop}) {
      font-size: 1.5vw;
    }
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 1.5em;
    }
  }
`;
export const Paragraph = styled.p`
  font-size: 1vw;
  height: max-content;
  min-height: 12em;
  padding: ${({ padding }) => padding};
  text-align: center;
  line-height: 23px;
  line-height: 1.5em;
  @media (max-width: ${({ theme }) => theme.breakPoint.desktop}) {
    line-height: 2em;
    font-size: 80%;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    font-size: 1em;
  }
`;
export const ImgCard = styled.img`
  width: 100%;
  border-radius: 1em;
`;
