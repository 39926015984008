import styled from "styled-components";
export const DeviStyled = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-top: 4px solid ${({ theme }) => theme.colors.primaryOrange};
  overflow: hidden;
  padding-top: 3em;

`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  justify-content: space-evenly;
  color: ${({ theme }) => theme.colors.primaryGray};
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    flex-direction: column;
  }
`;
export const Infos = styled.section`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.8em 0em;
  margin: ${({ margin }) => margin || "1.5em 0em"};
  font-size: 1.2em;
  outline: none;
  background-color: ${({ bg, theme }) => bg || theme.colors.secondaryGray};
  border: 1px solid ${({ border, theme }) => border || theme.colors.primaryGray};
  border-radius: 0.3em;
  color: ${({ color }) => color || "inherit"};
  opacity: ${({ opacity }) => opacity || "0"};
  ${({ animation }) => animation};
  text-indent: 1em;
  @keyframes ApparitionBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const Select = styled.section`
  display: flex;
  width: 100%;
  background-color: ${({ bg, theme }) => bg || theme.colors.secondaryGray};
  border: 1px solid ${({ border, theme }) => border || theme.colors.primaryGray};
  border-radius: 0.3em;
  padding: 1em 0em;
  margin: 1.5em 0em;
  position: relative;
  font-size: 1.2em;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  ${({ animation }) => animation};
  text-indent: 1em;
  @keyframes ApparitionBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  span {
    flex: 6;
  }
  h5 {
    flex: 10;
  }
  svg {
    flex: 1;
    cursor: pointer;
  }
`;
export const ListSelect = styled.ul`
  display: ${({ display }) => display};
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  flex-direction: column;
  width: 96%;
  height: 15em;
  position: absolute;
  top: 114%;
  z-index: 1;
  border: 1px solid white;
  overflow-y: auto;
  border: 1px solid ${({ border, theme }) => border || theme.colors.primaryGray};
  cursor: pointer;
  li {
    background-color: ${({ theme }) => theme.colors.secondaryGray};
    padding: 1em;
    cursor: pointer;
  }
`;
export const TextArea = styled.textarea`
  width: 87.5%;
  max-width: 87.5%;
  min-width: 87.5%;
  height: 10em;
  min-height: 10em;
  font-size: 1.5em;
  padding: ${({ padding }) => padding || "2em 1em 0em 1em"};
  border-radius: 0.3em;
  outline: none;
  color: ${({ color }) => color || "inherit"};
  background-color: ${({ bg, theme }) => bg || theme.colors.primaryGray};
  border: 1px solid
    ${({ border, theme }) => border || theme.colors.primaryWhite};
  opacity: 0;
  ${({ animation }) => animation};
  @keyframes ApparitionBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;
export const Send = styled.button`
  width: ${({ width }) => width || "55%"};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${({ color }) => color || "inherit"};
  background-color: ${({ bg, theme }) => bg || theme.colors.secondaryGray};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding || "1.3em 1em"};
  margin: ${({ margin }) => margin || "1.5em auto"};
  border-radius: 0.5em;
  opacity: ${({ opacity }) => opacity || "0"};
  position: ${({ position }) => position || "relative"};
  top: ${({ top }) => top || "0"};
  right: ${({ right }) => right || "0"};
  font-size: ${({ size }) => size || "unset"};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: ${({ colorHover, theme }) =>
      colorHover || theme.colors.primaryWhite};
    background-color: ${({ bgHover, theme }) =>
      bgHover || theme.colors.primaryGray};
    border: ${({ borderHover, theme }) =>
      borderHover || `1px solid ${theme.colors.primaryWhite}`};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: ${({ widthMob }) => widthMob || "50%"};
  }
  ${({ animation }) => animation};
  @keyframes ApparitionBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  svg {
    font-size: 1.5em;
  }
`;
