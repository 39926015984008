import bg from "../../../assets/qualité.jpg";
import styled from "styled-components";
export const QualityStyled = styled.section`
  background-image: url(${bg});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: luminosity;
  background-color: ${({ theme }) => theme.colors.overlay};
`;
export const QualityBox = styled.section`
  width: 70%;
  padding: 2em 0em;
  opacity: 0;
  ${({ animation }) => animation};
  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
`;
export const QualityContent = styled.section`
  width: 70%;
  height: 9em;
  background-color: rgba(28, 35, 45, 0.7);
  color: ${({ theme }) => theme.colors.primaryWhite};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  svg {
    font-size: 1.8em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    height: 10em;
    width: 100%;
  }
`;
export const QualityInfo = styled.section`
  width: 100%;
  h4 {
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    h4 {
      text-align: center;
      font-size: 1.5em;
      font-weight: 500;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    p {
      text-align: center;
      font-size: 1em;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0em 1em;
    }
  }
`;
export const QualityStep = styled.section`
  width: 30%;
  height: 9em;
  background-color: ${({ theme }) => theme.colors.primaryOrange};
  padding: 3em 0em;
  ${({ radius }) => radius}
  span {
    font-size: 2em;
    font-weight: 600;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 70%;
    height: 6em;
    padding: 1em 0em;
    span {
      font-size: 2em;
      font-weight: 600;
    }
  }
`;
