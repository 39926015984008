import styled from "styled-components";

export const MainStyled = styled.main`
  position: relative;
`;

export const Title = styled.h3`
  font-size: 1.5em;
  font-weight: 600;
  padding: ${({ padding }) => padding || "2em 0em"};
  position: relative;
  color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity || "1"};
  ${({ animation }) => animation};
  flex: ${({ flex }) => flex || "unset"};
  svg {
    cursor: pointer;
    font-size: .8em;
    &:hover {
      color: tomato;
    }
  }

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    font-size: 1.1em;
  }
  &:before {
    content: "";
    width: 2em;
    height: 0.2em;
    position: absolute;
    top: ${({ top }) => top || "3.6em"};
    left: 0;
    background-color: ${({ bgcolor, theme }) =>
      bgcolor || theme.colors.primaryGray};
    transform-origin: left top;
    transform: scale(1, 1);
  }
`;
