import styled from "styled-components";

export const PhoneStyled = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  opacity: ${({ opacity }) => opacity || "0"};
  ${({ animation }) => animation};
  z-index: 2;
  @keyframes ApparitionBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const InputPhone = styled.input`
  width: 100%;
  padding: ${({ padding }) => padding || "0.8em 1em"};
  margin: ${({ margin }) => margin || "1.5em 0em"};
  font-size: 1.2em;
  outline: none;
  text-indent: 4em;
  border-radius: 0.3em;
  background-color: ${({ bg, theme }) => bg || theme.colors.secondaryGray};
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export const Dial = styled.section`
  display: flex;
  position: absolute;
  width: ${({ widthIcon }) => widthIcon || "15%"};
  top: ${({ top }) => top || "0em"};
  left: ${({ left }) => left || "0em"};
  border: transparent;
  padding: 0.9em 0em;
  margin: ${({ margin }) => margin || "1.5em 0em"};
  font-size: ${({ font }) => font || "1.2em"};
  opacity: 0;
  z-index: 3;
  cursor: pointer;
  ${({ animation }) => animation};
  @keyframes ApparitionBottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const Flag = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  color: ${({ color }) => color};
  svg {
    cursor: pointer;
    font-size: 1em;
  }
  img {
    margin-left: 0.5em;
    width: 35%;
  }
`;
export const ListSelect = styled.ul`
  display: ${({ display }) => display};
  background-color: ${({ bg, theme }) => bg || theme.colors.secondaryGray};
  border: 1px solid ${({ color }) => color};
  flex-direction: column;
  font-size: 1.1em;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: absolute;
  top: 78%;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
`;
export const FlagSelect = styled.li`
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  padding: 1em;
  cursor: pointer;
  img {
    width: 1em;
    margin-right: 0.5em;
  }
`;
