import styled from "styled-components";

export const StatStyled = styled.section`
  width: 100%;
  height: 8em;
  background-color: ${({ theme }) => theme.colors.primaryGray};
  color: ${({ theme }) => theme.colors.primaryWhite};
  margin: 0 auto;
  z-index: 2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  ${({ animation }) => animation};
  position: absolute;
  top: -5em;
  opacity: 0;
  border-radius: 0.5em;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  @keyframes Apparition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    height: 7em;
  }
  svg {
    color: ${({ theme }) => theme.colors.primaryOrange};
    font-size: 1.8em;
    position: relative;
    top: 0;
    cursor: auto;
    padding: 0.5em 0em;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryOrange};
    }
  }
  p {
    font-weight: 600;
  }
`;
export const Text = styled.section`
  font-size: 1em;
  padding: 0.7em 0em;
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    display: none;
  }
`;
