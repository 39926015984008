import { TitleStyled } from "./style";

const Title = ({ txt, color, align, position }) => {
  return (
    <TitleStyled color={color} align={align} position={position}>
      {txt}
    </TitleStyled>
  );
};

export default Title;
