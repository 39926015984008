import React from "react";
import {
  BottomStyled,
  Menu,
  Logo,
  MenuList,
  DevisBtn,
  TopSocial,
  MenuMobile,
  MenuMobileContent,
  List,
  MobileLang,
} from "./style";
import {
  GlobalSwitch,
  OpenList,
  defaultSwitch,
  setFunction,
} from "../../function";
import logo from "../../assets/Logo.png";
import logoMobile from "../../assets/Logo-Mobile.png";
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { RiMenu3Fill, RiCloseFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { theme } from "../../styles/theme";
import { Container } from "../../styles/container";
import Language from "../Languages";

const BottomBarre = ({ setDisplay, display, data, parameter }) => {
  const Breakpoint = display.screen <= 1024 ? 18 : 18;
  const selected = {
    color: theme.colors.primaryOrange,
  };
  const unselected = {
    color: theme.colors.primaryWhite,
  };
  const actif = (navData) => (navData.isActive ? selected : unselected);
  return (
    <BottomStyled
      bg={GlobalSwitch(
        "change",
        display.scroll,
        Breakpoint,
        "#1c232b",
        "transparent"
      )}
      position={GlobalSwitch(
        "change",
        display.scroll,
        Breakpoint,
        "0em",
        "5.5em"
      )}
      border={GlobalSwitch(
        "change",
        display.scroll,
        Breakpoint,
        "0.1em solid #f78513",
        "none"
      )}
    >
      <Container>
        <Menu>
          <Logo
            src={display.screen >= 599 ? logo : logoMobile}
            alt="Arabe Metals Logo"
            scale={GlobalSwitch(
              "change",
              display.scroll,
              100,
              "scale(.6)",
              " scale(0.5)"
            )}
            onClick={() => window.scrollTo(0, 0)}
          />
          <MenuList
            display={GlobalSwitch(
              "change",
              display.screen,
              1024,
              "flex",
              "none"
            )}
          >
            <List
              align={display.lang === "AR" ? "end" : "start"}
              position={display.lang === "AR" ? "right:0;" : "left:0;"}
              origin={display.lang === "AR" ? "right" : "left"}
            >
              <NavLink
                style={actif}
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setFunction(setDisplay, "devisBtn", true);
                }}
              >
                {data.menu[0]}
              </NavLink>
            </List>
            <List
              align={display.lang === "AR" ? "end" : "start"}
              position={display.lang === "AR" ? "right:0;" : "left:0;"}
              origin={display.lang === "AR" ? "right" : "left"}
            >
              <NavLink
                style={actif}
                to="/products"
                onClick={() => {
                  window.scrollTo(0, 760);
                  setFunction(setDisplay, "devisBtn", false);
                }}
              >
                {data.menu[1]}
              </NavLink>
            </List>
            <List
              align={display.lang === "AR" ? "end" : "start"}
              position={display.lang === "AR" ? "right:0;" : "left:0;"}
              origin={display.lang === "AR" ? "right" : "left"}
            >
              <NavLink
                style={actif}
                to="/contact"
                onClick={() => {
                  window.scrollTo(0, 760);
                  setFunction(setDisplay, "devisBtn", false);
                }}
              >
                {data.menu[2]}
              </NavLink>
            </List>
          </MenuList>
          {display.devisBtn ? (
            <DevisBtn
              padding={GlobalSwitch(
                "change",
                display.scroll,
                100,
                "1.5em 0em",
                "1.5em 0em"
              )}
              onClick={() => window.scrollTo(0, 4360)}
            >
              {data.devis}
            </DevisBtn>
          ) : null}

          <TopSocial>
            <li>
              <a
                href="https://www.facebook.com/arabmetalsalgerie/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/arab-metals-spa/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="##">
                <FaTwitterSquare />
              </a>
            </li>
          </TopSocial>
          <MenuMobile>
            <RiMenu3Fill
              onClick={() => setFunction(setDisplay, "MenuSide", true)}
            />
          </MenuMobile>
        </Menu>
        <MenuMobileContent
          width={defaultSwitch("change", display.MenuSide, "50%", "0%")}
        >
          <RiCloseFill
            onClick={() => setFunction(setDisplay, "MenuSide", false)}
            display={OpenList(display.MenuSide)}
          />
          <MenuList
            display={defaultSwitch("change", display.MenuSide, "flex", "none")}
            width={"100%"}
            direction={"column"}
            align={"center"}
          >
            <MobileLang>
              <Language
                padding={0}
                setDisplay={setDisplay}
                display={display}
                parameter={parameter}
              />
            </MobileLang>

            <List padding={"1em 0em 2em 0em"} size={"1.5em"}>
              <NavLink
                style={actif}
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setFunction(setDisplay, "devisBtn", true);
                  setFunction(setDisplay, "MenuSide", false);
                }}
              >
                ACCUEIL
              </NavLink>
            </List>
            <List padding={"1em 0em 2em 0em"} size={"1.5em"}>
              <NavLink
                style={actif}
                to="/products"
                onClick={() => {
                  window.scrollTo(0, 760);
                  setFunction(setDisplay, "devisBtn", false);
                  setFunction(setDisplay, "MenuSide", false);
                }}
              >
                PRODUITS
              </NavLink>
            </List>
            <List padding={"1em 0em 2em 0em"} size={"1.5em"}>
              <NavLink
                style={actif}
                to="/contact"
                onClick={() => {
                  window.scrollTo(0, 760);
                  setFunction(setDisplay, "devisBtn", false);
                  setFunction(setDisplay, "MenuSide", false);
                }}
              >
                CONTACT
              </NavLink>
            </List>
          </MenuList>
        </MenuMobileContent>
      </Container>
    </BottomStyled>
  );
};

export default BottomBarre;
