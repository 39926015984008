import React from "react";
import { LanguagePanel } from "./style";
import { setFunction } from "../../../function";
import { theme } from "../../../styles/theme";
const Panel = ({ display, setDisplay, flag, parameter, data }) => {
  const multilang = (Lang) => {
    return parameter.multilang
      ? (setFunction(setDisplay, "lang", Lang),
        setFunction(setDisplay, "languagePanel", false))
      : null;
  };
  const active = {
    filter: parameter.multilang ? "none" : " grayscale(1)",
    unset: parameter.multilang ? "pointer" : " unset",
    color: parameter.multilang
      ? theme.colors.primaryGray
      : theme.colors.textColor,
  };
  return (
    <>
      {display.languagePanel ? (
        <LanguagePanel>
          <ul>
            <li
              style={{
                backgroundColor:
                display.lang === "FR"
                    ? theme.colors.primaryOrange
                    : " transparent",
              }}
              onClick={() => {
                setFunction(setDisplay, "languagePanel", false);
                setFunction(setDisplay, "lang", "FR");
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/flags/4x3/${flag[73].code}.svg`}
                alt="flag"
              />
              <span>{data.fr}</span>
            </li>
            <li
              style={{
                backgroundColor:
                display.lang === "EN"
                    ? theme.colors.primaryOrange
                    : " transparent",
              }}
              onClick={() => {
                multilang("EN");
              }}
            >
              {" "}
              <img
                src={`${process.env.PUBLIC_URL}/flags/4x3/${flag[233].code}.svg`}
                alt="flag"
                style={{ filter: active.filter, cursor: active.unset }}
              />
              <span style={{ color: active.color, cursor: active.unset }}>
                {data.en}
              </span>
            </li>
            <li
              style={{
                backgroundColor:
                display.lang === "AR"
                    ? theme.colors.primaryOrange
                    : " transparent",
              }}
              onClick={() => {
                multilang("AR");
              }}
            >
              {" "}
              <img
                src={`${process.env.PUBLIC_URL}/flags/4x3/${flag[3].code}.svg`}
                alt="flag"
                style={{ filter: active.filter, cursor: active.unset }}
              />
              <span style={{ color: active.color, cursor: active.unset }}>
                {data.ar}
              </span>
            </li>
          </ul>
        </LanguagePanel>
      ) : null}
    </>
  );
};

export default Panel;
