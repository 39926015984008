import React, { useEffect, useState } from "react";
import { AboutStyled } from "./style";
import Flex from "../../../styles/flex";
import { Container } from "../../../styles/container";
import { Display, GlobalSwitch } from "../../../function";
import { AboutSection, Border, Img, Picture } from "./style";
import Title from "../../common/title";
const About = ({ display, data }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 260 : 550;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  return (
    <AboutStyled>
      <Container>
        <Flex
          padding={GlobalSwitch(
            "changeRev",
            display.screen,
            1024,
            "3em 0em 2em 0em",
            "10em 0em 5em 0em"
          )}
          direction={GlobalSwitch(
            "changeRev",
            display.screen,
            1024,
            "column",
            null
          )}
        >
          <AboutSection
            animation={GlobalSwitch(
              "change",
              localScroll,
              Breakpoint,
              "animation: ApparitionLeft .5s forwards",
              null
            )}
          >
            <Title
              txt={data.about.title}
              align={display.lang === "AR" ? "end" : "start"}
              position={display.lang === "AR" ? "right:0;" : "left:0;"}
            />

            <section
              style={{ textAlign: display.lang === "AR" ? "end" : "start" }}
            >
              <h1>{data.about.name}</h1>
              <p>{data.about.txt}</p>
            </section>
          </AboutSection>
          <Picture
            animation={GlobalSwitch(
              "change",
              localScroll,
              Breakpoint,
              "animation: ApparitionRight .5s forwards",
              null
            )}
          >
            <Border>
              <Img
                src={`${process.env.PUBLIC_URL}${data.about.img}`}
                alt="about-img"
              />
            </Border>
          </Picture>
        </Flex>
      </Container>
    </AboutStyled>
  );
};

export default About;
