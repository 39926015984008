import React from "react";
import { ShareStyled } from "./style";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
const SharePanel = ({ display, item }) => {
  console.log(window.location.href);
  const shareUrl =window.location.href;
  return (
    <>
      {display.sharePanel ? (
        <ShareStyled>
          <ul>
            <li>
              <FacebookShareButton
                url={shareUrl}
                hashtag={`#ArabMetals`}
              
              >
                <FacebookIcon size={30} />
              </FacebookShareButton>
            </li>
            <li>
              <LinkedinShareButton
                url={shareUrl}
                quote={item.dis}
                hashtag={`#ArabMetals`}
              >
                <LinkedinIcon size={30} />
              </LinkedinShareButton>
            </li>
            <li>
              <TwitterShareButton
                url={shareUrl}
                quote={item.dis}
                hashtag={`#ArabMetals`}
              >
                <TwitterIcon size={30} />
              </TwitterShareButton>
            </li>
          </ul>
        </ShareStyled>
      ) : null}
    </>
  );
};

export default SharePanel;
