import React, { useState, useEffect } from "react";
import { Display, GlobalSwitch, Icons } from "../../../function";
import { Container } from "../../../styles/container";
import Flex from "../../../styles/flex";

import {
  QualityBox,
  QualityContent,
  QualityInfo,
  QualityStep,
  QualityStyled,
} from "./style";

const Quality = ({ data, display }) => {
  const [localScroll, setLocalScroll] = useState(0);
  const Breakpoint = display.screen <= 1024 ? 260 : 2900;
  useEffect(() => {
    Display(setLocalScroll, Breakpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);
  return (
    <QualityStyled>
      <Container>
        <Flex width={"100%"} justify={"center"} wrap={"wrap"}>
          {data.test.map((test, i) => (
            <QualityBox
              key={i}
              animation={GlobalSwitch(
                "change",
                localScroll,
                Breakpoint,
                `animation: ApparitionLeft ${i / 2}s forwards`,
                null
              )}
            >
              <Flex
                width={"100%"}
                direction={
                  display.screen <= 1024
                    ? "column"
                    : GlobalSwitch("TestId", test.id, 2, "row", "row-reverse")
                }
                justify={"space-between"}
              >
                <QualityStep
                  radius={
                    display.screen <= 1024
                      ? ""
                      : GlobalSwitch(
                          "TestId",
                          test.id,
                          2,
                          "border-top-left-radius: 0%; border-bottom-left-radius: 2em;",
                          "border-top-right-radius: 2em; border-bottom-right-radius: 0%;"
                        )
                  }
                >
                  <Flex direction={"column"}>
                    <span> {Icons(test.icon)}</span>
                    <h4 style={{textAlign:"center"}}>{test.title}</h4>
                  </Flex>
                </QualityStep>
                <QualityContent>
                  <Flex
                    width={"100%"}
                    height={"100%"}
                    justify={"space-evenly"}
                    direction={GlobalSwitch(
                      "TestId",
                      test.id,
                      2,
                      "row",
                      "row-reverse"
                    )}
                  >
                    <Flex
                      width={GlobalSwitch(
                        "change",
                        display.screen,
                        1024,
                        "75%",
                        "100%"
                      )}
                      height={"100%"}
                    >
                      <QualityInfo>
                        <p>{test.txt}</p>
                      </QualityInfo>
                    </Flex>
                  </Flex>
                </QualityContent>
              </Flex>
            </QualityBox>
          ))}
        </Flex>
      </Container>
    </QualityStyled>
  );
};

export default Quality;
