import styled from "styled-components";

export const ShareStyled = styled.section`
  position: absolute;
  top: 60%;
  left: 60%;
  color: ${({ theme }) => theme.colors.primaryGray};
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 5px;
  z-index: 4;
  ul {
    width: 100%;
    display: flex;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 1em 1.5em;
  }
  svg {
    padding-right: 0.2em;
    font-size: 2em;
    cursor: pointer;
  }
`;
