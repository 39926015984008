import styled from "styled-components";

export const MapStyled = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryGray};
  color: ${({ theme }) => theme.colors.primaryWhite};
`;
export const MapContent = styled.section`
  width: 65%;
  padding: 2em 0em;
  opacity: 0;
  ${({ animation }) => animation};

  @keyframes ApparitionRight {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding-bottom: 3em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
`;
export const Iframe = styled.iframe`
  width: 100%;
  height: ${({ height }) => height};
`;
export const ContactInfo = styled.section`
  width: 35%;
  padding-bottom: 2em;

  opacity: 0;
  ${({ animation }) => animation};

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding-bottom: 3em;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    width: 30%;
    position: fixed;
    top: 79%;
    left: 0;
    z-index: 2;
    padding-bottom: 0;
  }
`;
export const QrCode = styled.section`
  width: 100%;
  display: ${({ display }) => display};
  align-items: center;
  justify-content: flex-start;
  padding: 0em 5em;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 50%;
    padding: 1em 5em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    padding: 0em 0em;
  }
  img {
    width: 50%;
    padding: 2em 0em;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      padding: 0em 0em;
      width: 8em;
    }
  }
`;
export const Infomations = styled.section`
  padding: 3em 5em;
  span {
    font-size: 1.2em;
    font-weight: 600;
    padding-bottom: 1em;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 1em;
      padding: 0em 1em 1em 1em;
    }
  }
  p {
    font-size: 1.2em;
    padding-bottom: 1em;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 1em;
      padding: 0em 1em 1em 1em;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    padding: 0em 0em;
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.mobile}) {
    display: none;
  }
`;
