import styled from "styled-components";

export const DetailStyled = styled.section`
  color: ${({ theme }) => theme.colors.primaryGray};
  padding-top: 3.7em;
  svg {
    font-size: 1.5em;
  }
`;
export const DetailBoxStyled = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  opacity: ${({ opacity }) => opacity || "none"};
  ${({ animation }) => animation || "none"};

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    flex-direction: column;
  }
`;
export const DetailImg = styled.img`
  width: 50%;
  z-index: 2;
  border-radius: 2em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  opacity: ${({ opacity }) => opacity || "none"};
  ${({ animation }) => animation || "none"};

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
`;
export const DetailText = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  padding: 2em;
  @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
    width: 100%;
  }
  h1 {
    font-size: 2em;
    font-weight: 500;
    @media (max-width: ${({ theme }) => theme.breakPoint.tab}) {
      font-size: 1.1em;
    }
  }
  h2 {
    font-size: 1.2em;
    font-style: italic;
    padding-bottom: 1.5em;
  }
  p {
    font-size: 1.2em;
  }
`;
export const OpenDevis = styled.button`
  border: ${({ border }) => border};
  background-color: ${({ bg }) => bg};
  padding: 1em 2em;
  border-radius: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: ${({ color }) => color};
    color: ${({ theme }) => theme.colors.primaryWhite};
    transition: 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
`;
export const Action = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.1;
  padding: 0.2em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  ${({ selected }) => selected}

  opacity: ${({ opacity }) => opacity || "none"};
  ${({ animation }) => animation || "none"};

  @keyframes ApparitionLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  a {
    color: inherit;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primaryOrange};
    transition: 0.3s ease;
  }
  svg {
    font-size: 2em;
  }
`;
